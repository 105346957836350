import { Hidden, withWidth } from '@material-ui/core'
import {
  IApiOtherWithContracts,
  IApiVehicleContract,
  IApiVehicleWithContracts,
  IProviderWithContracts,
  TContractObject,
} from '@fragus/sam-types'
import { ITab } from 'pages/customer/ContractDetailsPage'
import React, { Component } from 'react'
import { compose } from 'recompose'
import VehicleListDesktop from './Desktop'
import VehicleListMobile from './Mobile'

interface IProps {
  open: boolean
  onCloseSidebar: () => void
  providers: IProviderWithContracts[]
  // vehicles: IApiVehicleWithContracts[] | IApiOtherWithContracts[]
  selectedContract: IApiVehicleWithContracts | IApiOtherWithContracts | undefined
  onContractSelect: (
    selectedContract: IApiVehicleContract | undefined,
    selectedVehicle: IApiVehicleWithContracts | IApiOtherWithContracts | undefined,
  ) => void
  tabSetup: {
    hideTabs: boolean
    tabs: ITab<TContractObject>[]
    activeTab: TContractObject
    tabClasses: { container: string; tab: string; activeTab: string }
    handleTabChange: (tab: any) => void
  }
}

class AppSidebar extends Component<IProps> {
  public render() {
    const { open, onCloseSidebar, providers /*, vehicles*/, onContractSelect, selectedContract } = this.props
    return (
      <>
        <Hidden lgUp={true}>
          <VehicleListMobile
            open={open}
            onCloseSidebar={onCloseSidebar}
            providers={providers}
            // vehicles={vehicles}
            onContractSelect={onContractSelect}
            selectedContract={selectedContract}
            tabSetup={this.props.tabSetup}
          />
        </Hidden>
        <Hidden mdDown={true}>
          <VehicleListDesktop
            providers={providers}
            // vehicles={vehicles}
            onContractSelect={onContractSelect}
            selectedContract={selectedContract}
          />
        </Hidden>
      </>
    )
  }
}

export default compose<IProps, IProps>(withWidth())(AppSidebar)
