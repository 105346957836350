import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Close as CloseIcon,
  ControlPointDuplicate as CopyOfferIcon,
  Edit as EditIcon,
  Send as SendIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@material-ui/icons'
import { formatDate } from '@omnicar/sam-format'
import {
  ContractState,
  ContractType,
  IAdminCustomer,
  IBasicContractInfo,
  PaymentGateway,
  UserRole,
} from '@fragus/sam-types'
import ActionTypes from 'actions/ActionTypes'
import { contractFlowUpdate, IContractFlowUpdate } from 'actions/contractFlowActions'
import {
  cancelPendingTermination,
  closeContract,
  closeWarranty,
  createPendingTermination,
  getContractOfferCopy,
  getMinimumTerminationDate,
  resendOffer,
  settleContract,
} from 'api/api'
import classNames from 'classnames'
import PendingTerminationForm from 'components/admin/Contract/Details/ActionButtons/DialogContent/PendingTerminationForm'
import SettlementConfirmationForm from 'components/admin/Contract/Details/SettlementForm/SettlementConfirmationForm'
import ConfirmDialog from 'components/ConfirmDialog'
import { LayoutBlock } from 'components/Mui/Layout'
import SpinnerButton from 'components/Mui/SpinnerButton'
import moment, { Moment } from 'moment'
import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { IRootState } from 'reducers/initialState'
import { contractDetailsPath, contractFlowPath } from 'routes/paths'
import { selectSettlementAttachments } from 'selectors/contractSettlement'
import { createPanelStyles, palette } from 'theme'
import { t } from 'translations/translationFunctions'
import { IContractFlow } from 'types/contractFlow'
import { TAttachment } from 'types/settlement'
import { trackEvent } from 'utils/analytics'
import { mapContractOfferCopy } from 'utils/contractFlow'
import browserHistory from 'utils/history'
import { getProvider, getRole, isUseWhiteBGTheme } from 'utils/localStorage'
import notify from 'utils/notify/notify'
import { compose } from 'recompose'
import Typography from 'components/Typography'
import DatePicker from 'components/DatePicker'

interface IReducerProps {
  contractFlow: IContractFlow
  attachments: TAttachment[]
}

interface IActionProps {
  contractFlowUpdate: (contract: Partial<IContractFlow>) => IContractFlowUpdate
}

interface IOwnProps {
  id: string
  basicInfo: IBasicContractInfo | undefined
  status: ContractState | undefined
  contractType: ContractType | undefined
  paymentGateway: PaymentGateway | undefined
  contractVersionCallback: () => void
  reloadContractInfo: () => void
  buyNowDisabled: boolean
  initContractPayment: () => void
  gettingContractPaymentData: boolean
  customer: IAdminCustomer
  tooFewPayments: boolean
  canCopyType?: boolean
  isSuperAdmin: boolean
  onSettleMessageShow: () => void
  onSettleMessageHide: () => void
  settledUnits?: null | number
  handleTabForSettleButton: () => void
  isSettlementTab: boolean
  expirationDate: string | Date
}

interface IDialogContent {
  closeDialogTitle?: string
  dialogText?: string
  closeDialogText?: string
  closeButtonText?: string
  confirmButtonText?: string
  confirmCloseButtonText?: string
  confirmCallback?: () => void
  confirmCallbackClose?: () => void
  showSpinner?: boolean
  additionalForm?: JSX.Element
}

type DialogStatus = 'CLOSED' | 'CLOSE_CONTRACT' | 'CLOSE_PENDING_TERMINATION'

interface IState {
  dialogOpen: DialogStatus
  isProgressCloseContract: boolean
  isProgressResend: boolean
  isProgressSettle: boolean
  isProgressCopyOffer: boolean
  showConfirmSendContractOffer: boolean
  userRole?: UserRole
  sendSettlementEmail: boolean
  externalTerminationError: boolean
  calculatedTerminationDate?: Date
  pendingTerminationDate: Moment | null
  showConfirmBuyNowStripe: boolean
  offerExpiresAt: Moment | null
}

type TProps = IOwnProps & WithStyles<typeof styles> & IActionProps & IReducerProps

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    root: {
      height: theme.spacing(4.5), // Tip: you can provide more than 1 argument: theme.spacing(1, 2) // = '8px 16px'.
    },
    button: {
      marginLeft: theme.spacing(3),
    },
    buttonLight: {
      backgroundColor: isUseWhiteBGTheme() ? theme.palette.secondary[500] : theme.palette.secondary[700],
      color: '#eee',
      '&:hover': {
        backgroundColor: isUseWhiteBGTheme() ? theme.palette.secondary[700] : theme.palette.secondary[800],
      },
    },
    buttonOutlineWhite: {
      color: 'rgba(255,255,255,1)',
      borderColor: 'rgba(255,255,255,1)',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.15)',
      },
    },
    buttonOutlineWhiteDisabled: {
      color: 'rgba(255,255,255,0.4) !important',
    },
    buttonSecondaryDisabled: {
      backgroundColor: 'rgba(63,162,153,0.4) !important',
      color: 'rgba(255,255,255,0.4) !important',
    },
    buttonClose: {
      backgroundColor: palette.context.warning[500],
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: palette.context.warning[700],
      },
    },
    closingTitle: {
      display: 'flex',
      justifyContent: 'center',
      minWidth: '200px',
    },
    center: {
      textAlign: 'center',
    },
    disabledSettleButton: {
      '&:disabled': {
        backgroundColor: palette.context.warning[100],
        color: palette.context.warning[500],
        opacity: 0.5,
      },
    },
    buttonWrapper: {
      display: 'inline-block',
    },
    dialogWrapper: {
      padding: '2rem',
    },

    dialogTitle: {
      marginBottom: '1rem',
    },
    dialogBody: {
      marginTop: '1rem',
    },
    dialogButtons: {
      display: 'flex',
      flexDirection: 'column',
      gap: '.5rem',
      marginTop: '2rem',
      marginBottom: '1rem',
    },
  })

class ContractDetailsActionButtons extends React.Component<TProps, IState> {
  public state: IState = {
    dialogOpen: 'CLOSED',
    isProgressCloseContract: false,
    isProgressResend: false,
    isProgressSettle: false,
    isProgressCopyOffer: false,
    showConfirmSendContractOffer: false,
    sendSettlementEmail: false,
    externalTerminationError: false,
    calculatedTerminationDate: undefined,
    pendingTerminationDate: null,
    showConfirmBuyNowStripe: false,
    offerExpiresAt: moment(this.props.basicInfo?.offerExpirationDate),
  }

  public componentDidMount() {
    this.setState({ userRole: getRole() })

    this.props.tooFewPayments && this.getCalculatedTerminationDate()
  }

  public componentDidUpdate(prevProps: TProps) {
    const { tooFewPayments, id } = this.props

    if (tooFewPayments && (prevProps.id !== id || tooFewPayments !== prevProps.tooFewPayments)) {
      this.getCalculatedTerminationDate()
    }
  }

  public render() {
    const {
      basicInfo,
      buyNowDisabled,
      classes,
      customer,
      gettingContractPaymentData,
      initContractPayment,
      paymentGateway,
      status,
      canCopyType,
      isSuperAdmin,
      onSettleMessageShow,
      onSettleMessageHide,
      settledUnits,
      isSettlementTab,
      handleTabForSettleButton,
      tooFewPayments,
    } = this.props
    const {
      dialogOpen,
      isProgressResend,
      isProgressCopyOffer,
      showConfirmSendContractOffer,
      userRole,
      pendingTerminationDate,
      showConfirmBuyNowStripe,
    } = this.state

    const isActiveOffer = status === ContractState.Offer
    const isActiveSubscr = status === ContractState.Active // Is Active Subscription Contract.
    const isSuspendedSubscr = status === ContractState.Suspended // Is Suspended Subscription/Contract.
    const isActivePrePaid = status === ContractState.ActivePrePaid // Is Active Pre/Dealer-Paid Contract.
    const ActivePendingTermination = status === ContractState.ActivePendingTermination
    const isClosable =
      (isActiveOffer || isActiveSubscr || isSuspendedSubscr || isActivePrePaid || ActivePendingTermination) &&
      (isSuperAdmin || userRole === 'admin')

    const isOfferAny: boolean =
      !!status &&
      [
        ContractState.Offer,
        ContractState.OfferExpired,
        ContractState.OfferRejected,
        ContractState.OfferRevoked,
      ].includes(status)
    const isSettleableButtonVisible: boolean = !isOfferAny
    const isSettleButtonClickable: boolean =
      userRole === 'admin' && (status === ContractState.Terminated || status === ContractState.Expired)
    const isSettled = status === ContractState.Settled

    let isCloseContractButtonVisible: boolean = false
    if (userRole === 'superadmin') {
      isCloseContractButtonVisible = true
    } else if (basicInfo?.contractProductType === 'ServiceAgreement') {
      isCloseContractButtonVisible = userRole === 'seller' || userRole === 'admin'
    } else if (basicInfo?.contractProductType === 'Warranty') {
      isCloseContractButtonVisible = userRole === 'admin'
    }

    const disableActivation = this.isWarrantyContract() && basicInfo && basicInfo.productHasWarranty

    const isExtendable = status === ContractState.Active && paymentGateway !== 'NONE'
    const isCopyable = isActiveOffer && basicInfo && basicInfo.extendedFromId === null && canCopyType

    const isUsingV4PricingTool: boolean =
      getProvider()?.isUsingV4PricingTool || getProvider()?.isUseV4PTOnlyForSigning || false
    const isHideExtendContractButton: boolean = isUsingV4PricingTool // Currently Extend Contract for V4PT hidded, due to not yet implemented 100%.
    const isHideCopyOfferButton: boolean = isUsingV4PricingTool // Currently Copy Offer for V4PT hidded, due to not yet implemented 100%.
    const {
      dialogText,
      closeButtonText,
      confirmButtonText,
      confirmCallback,
      showSpinner,
      closeDialogTitle,
      closeDialogText,
      confirmCloseButtonText,
      confirmCallbackClose,
      additionalForm,
    } = this.getDialogContent(status)

    return (
      <div className={classes.root}>
        {status === ContractState.ActivePendingTermination && (
          <SpinnerButton
            className={classNames(classes.button, classes.buttonLight)}
            variant="contained"
            onClick={this.handleCancelPendingTerminationClick}
            disabled={showSpinner}
            showSpinner={showSpinner}
          >
            {t('Cancel Pending Termination')}
          </SpinnerButton>
        )}
        {isExtendable && !isHideExtendContractButton && (
          <SpinnerButton
            className={classNames(classes.button, classes.buttonLight)}
            variant="contained"
            onClick={this.handleExtendContract}
            showSpinner={showSpinner}
            IconComponent={EditIcon}
            IconClasses={classes.panelActionsButtonIcon}
          >
            {t('Extend Contract')}
          </SpinnerButton>
        )}
        {isCopyable && !isHideCopyOfferButton && (
          <SpinnerButton
            className={classNames(classes.button, classes.buttonLight)}
            variant="contained"
            onClick={this.handleCopyOffer}
            showSpinner={isProgressCopyOffer}
            IconComponent={CopyOfferIcon}
            IconClasses={classes.panelActionsButtonIcon}
          >
            {t('Copy offer')}
          </SpinnerButton>
        )}
        {isActiveOffer && (
          <>
            <SpinnerButton
              className={classNames(classes.button, classes.buttonLight)}
              classesButton={{ disabled: classes.buttonOutlineWhiteDisabled }}
              variant="contained"
              onClick={this.showConfirmSendContractOffer}
              showSpinner={isProgressResend}
              IconComponent={SendIcon}
              IconClasses={classes.panelActionsButtonIcon}
            >
              {t('Resend offer')}
            </SpinnerButton>
          </>
        )}
        {isActiveOffer && paymentGateway === 'Stripe' && (
          <>
            <SpinnerButton
              className={classNames(classes.button, classes.buttonLight)}
              classesButton={{ disabled: classes.buttonSecondaryDisabled }}
              variant="contained"
              onClick={() => {
                this.setState({ showConfirmBuyNowStripe: true })
              }}
              showSpinner={gettingContractPaymentData}
              disabled={buyNowDisabled || disableActivation}
              IconComponent={ShoppingCartIcon}
              IconClasses={classes.panelActionsButtonIcon}
            >
              {t('Buy now')}
            </SpinnerButton>
          </>
        )}

        {isCloseContractButtonVisible && (
          <>
            {isClosable && (
              <Button
                className={classNames(classes.button, classes.buttonClose)}
                variant="contained"
                onClick={this.handleCloseContract}
              >
                <CloseIcon className={classes.panelActionsButtonIcon} />
                {closeButtonText}
              </Button>
            )}
            {isSettleableButtonVisible && (
              <div
                className={classes.buttonWrapper}
                onMouseOver={onSettleMessageShow}
                onMouseLeave={onSettleMessageHide}
              >
                <Button
                  classes={{
                    root: classNames(classes.button, classes.buttonClose),
                    disabled: classes.disabledSettleButton,
                  }}
                  disabled={!isSettleButtonClickable || isSettled || typeof settledUnits !== 'number'}
                  variant="contained"
                  onClick={isSettlementTab ? this.handleSettleContract : handleTabForSettleButton}
                >
                  {isSettled ? t('Settled') : t('Settle contract')}
                </Button>
              </div>
            )}
          </>
        )}

        {showConfirmSendContractOffer && (
          <div className={classes.dialogWrapper}>
            <Dialog open={showConfirmSendContractOffer} onClose={this.handleCloseShowConfirmSend}>
              <DialogContent>
                <Typography variant="body1" className={classes.dialogTitle}>
                  {t('Pick a expiration date for the offer')}
                </Typography>
                <DatePicker
                  id={'datepicker-contract'}
                  onDateChange={(e): void => this.setState({ offerExpiresAt: e })}
                  date={this.state.offerExpiresAt}
                  initialDate={this.state.offerExpiresAt || moment()}
                  minDate={moment().add(1, 'days')}
                  datePickerWithoutInput
                />
                <Typography variant="body1" className={classes.dialogBody}>
                  {t('Send offer to:')}
                </Typography>

                <Typography variant="body1">{customer.email}</Typography>
                <Typography variant="body1">{customer.name}</Typography>
                <div className={classes.dialogButtons}>
                  <SpinnerButton
                    color="primary"
                    variant="contained"
                    onClick={this.handleResendOffer}
                    className={classes.dialogButton}
                  >
                    {t('Yes, resend offer')}
                  </SpinnerButton>
                  <SpinnerButton
                    variant="contained"
                    onClick={this.handleCloseShowConfirmSend}
                    className={classes.dialogButton}
                  >
                    {t('No, Cancel')}
                  </SpinnerButton>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        )}
        {showConfirmBuyNowStripe && (
          <ConfirmDialog
            open={true}
            titleTrl={t('Buy customer monthly subscription')}
            contentTrlText={t('Buy this contract now as a customer monthly subscription contract, are you sure?')}
            onConfirm={initContractPayment}
            onCancel={() => {
              this.setState({ showConfirmBuyNowStripe: false })
            }}
            captionTrlConfirmButton={t('Yes, buy now')}
            captionTrlCancelButton={t('No, Cancel')}
          />
        )}

        <Dialog open={dialogOpen !== 'CLOSED'} onClose={this.closeDialog}>
          {this.state.isProgressCloseContract ? (
            <DialogTitle className={classes.closingTitle}>
              <LayoutBlock dense={true}>{`${t('Please wait while contract is being closed')}...`}</LayoutBlock>
              <div className={classes.center}>
                <CircularProgress size={32} color="secondary" />
              </div>
            </DialogTitle>
          ) : (
            <React.Fragment>
              <DialogTitle>{closeDialogTitle}</DialogTitle>
              {dialogText && (
                <DialogContent>
                  <DialogContentText>
                    {dialogOpen === 'CLOSE_CONTRACT' ? closeDialogText! : dialogText!}.
                  </DialogContentText>
                </DialogContent>
              )}
              {additionalForm}

              <DialogActions>
                {
                  <SpinnerButton
                    onClick={dialogOpen === 'CLOSE_CONTRACT' ? confirmCallbackClose : confirmCallback}
                    variant="contained"
                    color="secondary"
                    disabled={showSpinner}
                    showSpinner={showSpinner}
                  >
                    {dialogOpen === 'CLOSE_CONTRACT' ? confirmCloseButtonText : confirmButtonText}
                  </SpinnerButton>
                }
                {dialogOpen === 'CLOSE_CONTRACT' && tooFewPayments && pendingTerminationDate && (
                  <Button variant="contained" color="secondary" onClick={this.handlePendingTerminationSubmit}>
                    {`${t('Close on')} ${formatDate(pendingTerminationDate.toDate())}`}
                  </Button>
                )}
                <Button onClick={this.closeDialog} color="primary">
                  {t('Cancel')}
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
        </Dialog>
        {dialogOpen === 'CLOSE_PENDING_TERMINATION' && (
          <ConfirmDialog
            open={true}
            titleTrl={t('Confirm cancellation')}
            onConfirm={this.handlePendingTerminationCancel}
            onCancel={this.closeDialog}
            captionTrlConfirmButton={t('Yes')}
            captionTrlCancelButton={t('No')}
          />
        )}
      </div>
    )
  }

  private handleSendSettlementEmailChange = () =>
    this.setState((prevState) => ({
      sendSettlementEmail: !prevState.sendSettlementEmail,
    }))

  private closeDialog = () => {
    this.setState({ dialogOpen: 'CLOSED' })
  }

  private handleCopyOffer = async () => {
    trackEvent('Contract offer', 'Copy offer')
    this.setState({ isProgressCopyOffer: true })

    const { contractFlow, id, contractFlowUpdate } = this.props

    const response = await getContractOfferCopy(id)

    if (response.data && response.statusCode === 200) {
      const contract = mapContractOfferCopy(response.data, contractFlow.payments)

      contractFlowUpdate(contract)
      browserHistory.push(contractFlowPath({ offerOnly: true }))
    } else {
      browserHistory.push(contractDetailsPath(id))
    }
    this.setState({ isProgressCopyOffer: false })
  }

  private handleExtendContract = () => {
    trackEvent('Contract details', 'Adjust contract')
    browserHistory.push(contractFlowPath({ identifier: this.props.id, offerOnly: false }))
  }

  private getCalculatedTerminationDate = async () => {
    const { id, paymentGateway } = this.props

    if (paymentGateway !== 'Stripe') {
      return
    }

    const result = await getMinimumTerminationDate(id)

    if (result.statusCode === 200 && result.data) {
      const calculatedTerminationDate = new Date(result.data)

      this.setState({ calculatedTerminationDate, pendingTerminationDate: moment(calculatedTerminationDate) })
    }
  }

  private handleTerminationMonthChange = (date: Moment) => {
    const calculatedTerminationDate = this.state.calculatedTerminationDate?.getDate()
    calculatedTerminationDate && this.setState({ pendingTerminationDate: date.date(calculatedTerminationDate) })
  }

  private handleTerminationDateChange = (pendingTerminationDate: Moment | null): void =>
    this.setState({ pendingTerminationDate })

  private handlePendingTerminationSubmit = async () => {
    const { id } = this.props
    const date = this.state.pendingTerminationDate?.toDate().toISOString()
    const result = date && (await createPendingTermination(id, { date }))

    if (result && result.statusCode === 200) {
      notify.success({ message: t('Success') })
      trackEvent('Contract details', 'Create pending termination')
      this.closeDialog()
      this.props.contractVersionCallback()
      this.setState(({ calculatedTerminationDate }) => ({
        pendingTerminationDate: calculatedTerminationDate ? moment(calculatedTerminationDate) : null,
      }))
    }
  }

  private handleCloseContract = () => this.setState({ dialogOpen: 'CLOSE_CONTRACT' })

  private handleCancelPendingTerminationClick = () => this.setState({ dialogOpen: 'CLOSE_PENDING_TERMINATION' })

  private handleSettleContract = () => this.setState({ dialogOpen: 'CLOSE_CONTRACT' })

  private handleResendOffer = async () => {
    trackEvent('Contract details', 'Resend offer')
    this.setState({ isProgressResend: true, showConfirmSendContractOffer: false })

    if (this.state.offerExpiresAt) {
      const offerExpiresAtDate = moment(this.state.offerExpiresAt).toDate()
      const data = await resendOffer(this.props.id, offerExpiresAtDate)
      this.setState({ isProgressResend: false })

      if (data.data) {
        notify.success({ message: t('Contract Offer mail was sent successfully') })

        // Reload Contract info along with updated Events with ResendOffer.
        this.props.reloadContractInfo()
      }
    }
  }

  private showConfirmSendContractOffer = async () => {
    this.setState({ showConfirmSendContractOffer: true })
  }

  private handleCloseShowConfirmSend = async () => {
    this.setState({ showConfirmSendContractOffer: false })
  }

  private confirmCloseContract = async () => {
    const { status, contractType, id } = this.props
    const isActive = status === ContractState.Active
    const isOffer = status === ContractState.Offer
    const isExternal = contractType === 'EXTERNAL'
    if (isActive) {
      trackEvent('Contract details', 'Close contract')
    } else if (isOffer) {
      trackEvent('Contract details', 'Close offer')
    }
    this.setState({ isProgressCloseContract: true })
    const result = await (isExternal ? closeWarranty(id) : closeContract(id))
    this.setState({ isProgressCloseContract: false })
    if (result.data) {
      if (isExternal) {
        this.setState({ externalTerminationError: !(result.data as { isTerminated: boolean }).isTerminated })
        notify.warning({
          message: t(
            'An error occurred in V4 request. You have to terminate the contract manually in V4 (super important)!',
          ),
        })
      }

      this.props.contractVersionCallback()
    } else {
      notify.error()
    }
    this.closeDialog()
  }

  private confirmSettleContract = async () => {
    trackEvent('Contract details', 'Settle contract')
    this.setState({ isProgressSettle: true })
    const { attachments, id } = this.props
    const { sendSettlementEmail } = this.state
    try {
      if (attachments.length) {
        const formData: FormData = new FormData()
        for (const a of attachments) {
          const res = await fetch(a.url)
          const blob = await res.blob()
          const file = new File([blob], a.name, { type: a.type })
          formData.append('docs', file)
        }
        await settleContract(id, sendSettlementEmail, true, formData)
        attachments.map((a) => URL.revokeObjectURL(a.url))
      } else {
        await settleContract(id, sendSettlementEmail, true)
      }
      this.props.contractVersionCallback()
    } catch (error) {
      notify.error() // @TODO: check error type notification
    }
    this.setState({ isProgressSettle: false })
    this.closeDialog()
  }

  private getDialogContent = (state?: ContractState): IDialogContent => {
    const defaultContent = {
      closeDialogTitle: '',
      dialogText: '',
      closeDialogText: '',
      closeButtonText: '',
      confirmButtonText: '',
      showSpinner: false,
    }
    if (!state) {
      return defaultContent
    }
    const { tooFewPayments, expirationDate } = this.props
    const {
      pendingTerminationDate,
      calculatedTerminationDate,
      sendSettlementEmail,
      isProgressCloseContract,
      isProgressSettle,
    } = this.state

    const isActive = state === ContractState.Active
    const isOffer = state === ContractState.Offer
    const isSuspended = state === ContractState.Suspended
    const isExternal = state === ContractState.ActivePrePaid
    const isTerminated = state === ContractState.Terminated
    const isExpired = state === ContractState.Expired
    const isCancelledSubscription = state === ContractState.CancelledSubscription
    const isContractInV4: boolean =
      state === ContractState.ActivePrePaid || this.props.basicInfo?.paymentGateway === 'V4'

    if (isActive || isOffer || isExternal) {
      if (isActive || isExternal) {
        return {
          // Close Button
          closeDialogTitle: t(
            tooFewPayments
              ? 'This contract has had fewer payments than the minimum number of payments for this contract. Choose desired closing date?'
              : 'Are you sure you want to close this contract?',
          ),
          closeDialogText: t(
            isContractInV4
              ? 'The customer will receive a mail notifying them that the warranty has been closed. Also, it will be terminated in V4 (in the background after a few minutes).'
              : 'The customer will receive a mail notifying them that the contract has been closed.',
          ),
          closeButtonText: t('Close Contract'),
          confirmCloseButtonText: t(tooFewPayments ? 'Close now' : 'Close Contract'),
          confirmCallbackClose: this.confirmCloseContract,
          // Show spiner
          showSpinner: isProgressCloseContract,
          additionalForm:
            tooFewPayments && pendingTerminationDate && calculatedTerminationDate ? (
              <PendingTerminationForm
                expirationDate={expirationDate}
                onDateChange={this.handleTerminationDateChange}
                onMonthChange={this.handleTerminationMonthChange}
                calculatedDate={calculatedTerminationDate}
                currentDate={pendingTerminationDate}
              />
            ) : undefined,
        }
      } else {
        return {
          // Close Button
          closeDialogTitle: t('Are you sure you want to close this offer?'),
          closeDialogText: t('The customer will no longer be able to activate this offer.'),
          closeButtonText: t('Close Offer'),
          confirmCloseButtonText: t('Close Offer'),
          confirmCallbackClose: this.confirmCloseContract,
          // Show spiner
          showSpinner: isProgressCloseContract,
        }
      }
    } else if (isSuspended) {
      return {
        closeButtonText: t('Terminate contract'),
        closeDialogTitle: t('Are you sure you want to close this contract?'),
        closeDialogText: t('The customer will receive a mail notifying them that the contract has been closed.'),
        confirmCloseButtonText: t('Close Contract'),
        confirmCallbackClose: this.confirmCloseContract,
        // Show spiner
        showSpinner: isProgressCloseContract,
      }
    } else if (isTerminated || isExpired || isCancelledSubscription) {
      return {
        // Settle Button
        closeDialogTitle: t('Are you sure you want to settle this contract?'),
        closeDialogText: t('The customer will receive a mail notifying them that the contract has been settled.'),
        confirmCloseButtonText: t('Settle contract'),
        confirmCallbackClose: this.confirmSettleContract,
        // Show spiner
        showSpinner: isProgressSettle,
        additionalForm: (
          <SettlementConfirmationForm
            handleChange={this.handleSendSettlementEmailChange}
            sendEmail={sendSettlementEmail}
          />
        ),
      }
    } else {
      // Contract state that doesn't need dialog
      return defaultContent
    }
  }

  private isWarrantyContract = (): boolean => {
    const { basicInfo } = this.props

    if (basicInfo) {
      const hasWarrantyOptions = basicInfo.additionalOptions.some((o) => (o.warrantyId ? true : false))
      return hasWarrantyOptions || basicInfo.contractProductType === 'Warranty'
    } else {
      return false
    }
  }

  private handlePendingTerminationCancel = async () => {
    const result = await cancelPendingTermination(this.props.id)
    if (result.statusCode === 200) {
      notify.success({ message: t('Success') })
      trackEvent('Contract details', 'Create pending termination')
      this.closeDialog()
      this.props.contractVersionCallback()
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  contractFlow: state.contractFlow,
  attachments: selectSettlementAttachments(state),
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  contractFlowUpdate: (values: Partial<IContractFlow>) => dispatch(contractFlowUpdate(values)),
})

export default compose<TProps, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ContractDetailsActionButtons)
