import { CardContent, InputLabel, TextField } from '@material-ui/core'
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { formatMileage } from '@omnicar/sam-format'
import { IMileageDurations, TMileageDurationsMap } from '@fragus/sam-types'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import React, { ChangeEvent } from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'
import { ContractFlowActivePanel, IContractFlow, IContractFlowMileageDuration } from 'types/contractFlow'
import { debugPrint } from 'utils/miscs'
import { zeroOrNumber } from 'utils/regex'
import { getObjectDiff } from '../../../../../utils/miscs'
import ConfirmDialog from '../../../../ConfirmDialog'
import { IKmMonths, MileageDurationsOO } from './MileageDurationsClass'
import ContractFlowDurationMileageSlider, { TMarks } from './Slider'

export const IS_DEBUG_SLIDER_PRINT: boolean = false // Note: 'false' is the default.
const PRE_STR: string = 'DurationMileageVer2: '

export interface IDurationMileageValues {
  mileage: IContractFlowMileageDuration
  duration: IContractFlowMileageDuration
}

interface IOwnProps {
  active: ContractFlowActivePanel
  activeEnum: ContractFlowActivePanel
  mileageDurationsMap: TMileageDurationsMap | null
  freeContract: boolean
  selectedTemplateName: string
  durationMileageValues: IDurationMileageValues
  onChange: (mileageKmData: IContractFlowMileageDuration, durationMonthsData: IContractFlowMileageDuration) => void
  isMileageDisabled: boolean
  defaultMileageFreeContract?: number
  defaultMileageKm: number // Default chosen mileage on slider, 0 if unknown or not given.
  defaultDurationMonths: number // Default chosen duration on slider, 0 if unknown or not given.
  contractFlow: IContractFlow // We need the contract flow as well, current duration months/mileage.
}

// TODO: Move to the CONFIG file, where it is easier to find.
const defaultHighMilegaWarningLimit = 2000000

type TProps = WithStyles<typeof styles> & IOwnProps

interface IState {
  mileageValueFreeContract?: number
  durationSliderData: IContractFlowMileageDuration | null
  mileageSliderData: IContractFlowMileageDuration | null
  mdInstance: MileageDurationsOO | null
  messageTitleTrl: string
  messageTrl: string
  isShowMessage: boolean
  mileageMarks: TMarks
  durationMarks: TMarks
  sliderStartupDuration: number
  sliderStartupMileage: number
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    inputLabel: {
      marginTop: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(2),
    },
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
    highMileageWarning: {
      color: customTheme.palette.context.attention[700],
    },
    highMileageWarningInput: {
      color: customTheme.palette.context.attention[700],
      '&:before': {
        borderBottomColor: customTheme.palette.context.attention[700],
      },
      '&:after': {
        borderBottomColor: customTheme.palette.context.attention[700],
      },
      '&:hover:before': {
        borderBottomColor: `${customTheme.palette.context.attention[700]} !important`,
      },
    },
  })

class ContractFlowDurationMileageVer2 extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)
    debugPrint(IS_DEBUG_SLIDER_PRINT, '\n-> ***** 5 ContractFlowDurationMileageVer2 ***\n')
    debugPrint(IS_DEBUG_SLIDER_PRINT, 'props:')

    this.state = {
      mileageValueFreeContract: props.defaultMileageFreeContract ? props.defaultMileageFreeContract : undefined,
      durationSliderData: null,
      mileageSliderData: null,
      mdInstance: null,
      messageTitleTrl: '',
      messageTrl: '',
      isShowMessage: false,
      mileageMarks: [],
      durationMarks: [],
      sliderStartupDuration: 0,
      sliderStartupMileage: 0,
    }
  }

  public componentDidUpdate(oldProps: TProps) {
    debugPrint(IS_DEBUG_SLIDER_PRINT, '\nContractFlowDurationMileageVer2: -> ***** componentDidUpdate ***\n')
    const { sliderStartupDuration, sliderStartupMileage } = this.state

    const oldMileageValue = oldProps.durationMileageValues!.mileage.value || this.state.mileageSliderData?.value
    const oldDurationValue = oldProps.durationMileageValues!.duration.value || this.state.durationSliderData?.value
    const oldDefaultMileageFreeContract = oldProps.defaultMileageFreeContract
    const oldSelectedTemplateName = oldProps.selectedTemplateName

    let newMileageValue = this.props.durationMileageValues?.mileage.value || sliderStartupMileage
    const newDurationValue = this.props.durationMileageValues?.duration.value || sliderStartupDuration
    let newDefaultMileageFreeContract = this.props.defaultMileageFreeContract
    const newSelectedTemplateName = this.props.selectedTemplateName

    let mileageValueFreeContract: number = oldMileageValue || 0
    let mileageSliderData: IContractFlowMileageDuration = this.props.durationMileageValues.mileage
    let durationSliderData: IContractFlowMileageDuration = this.props.durationMileageValues.duration

    let isUpdateState: boolean = false
    const isReInitSliders: boolean = !!(oldSelectedTemplateName && !this.state.mdInstance) // If we already have a selected template, but the durationMileage slider is not rendered then re-init it. (Happens when showing another page, and then returning back to contract creation.)

    if ((newSelectedTemplateName && newSelectedTemplateName !== oldSelectedTemplateName) || isReInitSliders) {
      const mileageDurationsMap = this.props.mileageDurationsMap

      if (!mileageDurationsMap) {
        throw Error('Cannot find required mileageDurationsMap')
      } else {
        const { defaultMileageKm, defaultDurationMonths } = this.props
        let mileageDurations: IMileageDurations | undefined = undefined

        mileageDurations = mileageDurationsMap!['' + newSelectedTemplateName]
        this.initSliders(mileageDurations, defaultMileageKm, defaultDurationMonths)

        return
      }
    }

    if (newMileageValue >= 0 && newMileageValue !== oldMileageValue) {
      const newMileageData: IContractFlowMileageDuration = {
        ...this.props.durationMileageValues.mileage,
        value: newMileageValue,
        wantedValue: newMileageValue,
        wantedMax: this.props.durationMileageValues.mileage.max,
      }

      mileageValueFreeContract = newMileageValue
      mileageSliderData = newMileageData
      isUpdateState = true
    }

    if (!this.props.durationMileageValues.mileage.value && oldProps.durationMileageValues.mileage.value) {
      // alert('blank durationMileageValues.mileage')
      console.debug('*reset mileageSliderData')
      mileageSliderData = { max: 0, min: 0, step: 0, value: 0, wantedMax: 0, wantedValue: 0 }
      isUpdateState = true
    }

    if (newDurationValue >= 0 && newDurationValue !== oldDurationValue) {
      const newDurationData: IContractFlowMileageDuration = {
        ...this.props.durationMileageValues.duration,
        value: newDurationValue,
        wantedValue: newDurationValue,
      }

      if (newDurationData && newDurationData !== oldProps.durationMileageValues.duration) {
        durationSliderData = newDurationData
      }

      isUpdateState = true
    }

    if (!this.props.durationMileageValues.duration.value && oldProps.durationMileageValues.duration.value) {
      // alert('blank durationMileageValues.duration')
      console.debug('*reset durationSliderData')
      durationSliderData = { max: 0, min: 0, step: 0, value: 0, wantedMax: 0, wantedValue: 0 }
      isUpdateState = true
    }

    if (newDefaultMileageFreeContract && newDefaultMileageFreeContract !== oldDefaultMileageFreeContract) {
      mileageValueFreeContract = newDefaultMileageFreeContract
      isUpdateState = true
    }

    debugPrint(IS_DEBUG_SLIDER_PRINT, 'newDurationValue = ' + newDurationValue)
    debugPrint(IS_DEBUG_SLIDER_PRINT, 'newMileageValue = ' + newMileageValue)

    if (isUpdateState) {
      const durationMarks: TMarks = !newMileageValue
        ? [{ value: 0, label: '' }]
        : this.state.mdInstance!.getDurationMarks(newMileageValue)
      const mileageMarks: TMarks = !newDurationValue
        ? [{ value: 0, label: '' }]
        : this.state.mdInstance!.getMileageMarks(newDurationValue)

      const oldMileageValueFreeContract = this.state.mileageValueFreeContract
      const oldMileageSliderData = { ...this.state.mileageSliderData }
      const oldDurationSliderData = { ...this.state.durationSliderData }
      const oldDurationMarks = this.state.durationMarks
      const oldMileageMarks = this.state.mileageMarks

      if (
        oldMileageValueFreeContract === mileageValueFreeContract &&
        oldMileageSliderData === mileageSliderData &&
        oldDurationSliderData === durationSliderData &&
        oldDurationMarks === durationMarks &&
        oldMileageMarks === mileageMarks
      ) {
        console.debug('*skip updating*')
      } else {
        const obj1: any = {
          mileageValueFreeContract: mileageValueFreeContract,
          mileageSliderData: { ...mileageSliderData },
          durationSliderData: { ...durationSliderData },
          durationMarks: durationMarks,
          mileageMarks: mileageMarks,
        }
        const obj2: any = {
          mileageValueFreeContract: this.state.mileageValueFreeContract,
          mileageSliderData: { ...this.state.mileageSliderData },
          durationSliderData: { ...this.state.durationSliderData },
          durationMarks: this.state.durationMarks,
          mileageMarks: this.state.mileageMarks,
        }
        const diff: string[] = getObjectDiff(obj1, obj2)
        console.debug('DMV2: diff:')
        console.debug(diff)

        console.debug('this.props.durationMileageValues.mileage:')
        console.debug(this.props.durationMileageValues.mileage)
        console.debug('oldProps.durationMileageValues.mileage:')
        console.debug(oldProps.durationMileageValues.mileage)
        console.debug('new mileageSliderData:')
        console.debug(mileageSliderData)
        console.debug('old mileageSliderData:')
        console.debug(oldMileageSliderData)

        console.debug('this.props.durationMileageValues.duration:')
        console.debug(this.props.durationMileageValues.duration)
        console.debug('oldProps.durationMileageValues.duration:')
        console.debug(oldProps.durationMileageValues.duration)
        console.debug('new durationSliderData:')
        console.debug(durationSliderData)
        console.debug('old durationSliderData:')
        console.debug(oldDurationSliderData)

        this.setState({
          mileageValueFreeContract: mileageValueFreeContract,
          mileageSliderData: mileageSliderData,
          durationSliderData: durationSliderData,
          durationMarks: durationMarks,
          mileageMarks: mileageMarks,
        })
      }
    }
  }

  private getEmptyDurationSliderData = (): IContractFlowMileageDuration => {
    return {
      min: 0,
      step: 0,
      value: 0,
      wantedValue: 0,
      max: 0,
      wantedMax: 0,
    }
  }

  /**
   * Inits the sliders and the instance of MileageDurationsOO.
   */
  private initSliders = (
    mileageDurations: IMileageDurations,
    defaultMileageKm: number | null,
    defaultDurationMonths: number | null,
  ): void => {
    debugPrint(IS_DEBUG_SLIDER_PRINT, 'initSliders(..) ***\n')

    const mdInstance: MileageDurationsOO = new MileageDurationsOO(mileageDurations)

    const MILEAGE_STEP = MileageDurationsOO.MILEAGE_STEP
    const DURATION_STEP = MileageDurationsOO.DURATION_STEP

    const middleMileage = MileageDurationsOO.getMiddleMileage(mileageDurations.options)
    const middleDuration = MileageDurationsOO.getMiddleDuration(mileageDurations.options)

    const sliderStartupMileage: number = !defaultMileageKm
      ? middleMileage
      : mdInstance.requestClosestMileage(defaultMileageKm)
    const sliderStartupDuration: number = !defaultDurationMonths
      ? middleDuration
      : mdInstance.requestClosestDuration(defaultDurationMonths, sliderStartupMileage)

    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'defaultMileageKm      = ' + defaultMileageKm)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'defaultDurationMonths = ' + defaultDurationMonths)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + '------------')
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'middleMileage     = ' + middleMileage)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'middleDuration    = ' + middleDuration + ' (of that mileage)')
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + '------------')
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + '\n')
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'sliderStartupMileage  = ' + sliderStartupMileage)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'sliderStartupDuration = ' + sliderStartupDuration)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + '\n')

    const mileageMin: number = mdInstance.getAbsMinMaxMileages().min
    const mileageMax: number = mdInstance.getAbsMinMaxMileages().max
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'mileage-MIN mo  = ' + mileageMin)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'mileage-MAX mo  = ' + mileageMax)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + '\n')

    const durationMin: number = mdInstance.getMinMaxDurations(sliderStartupMileage).min
    const durationMax: number = mdInstance.getMinMaxDurations(sliderStartupMileage).max
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'duration-MIN mo  = ' + durationMin)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'duration-MAX mo  = ' + durationMax)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + '\n')

    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + '\nmdInstance: ' + mdInstance)

    const durationSliderData: IContractFlowMileageDuration = {
      min: durationMin,
      step: DURATION_STEP,
      value: sliderStartupDuration, // In months.
      wantedValue: sliderStartupDuration, // In months.
      max: durationMax,
      wantedMax: durationMax,
    }
    const mileageSliderData: IContractFlowMileageDuration = {
      min: mileageMin,
      step: MILEAGE_STEP,
      value: sliderStartupMileage, // In km.
      wantedValue: sliderStartupMileage, // In km.
      max: mileageMax,
      wantedMax: mileageMax,
    }

    const mileageMarks: TMarks = mdInstance.getMileageMarks(sliderStartupDuration)
    const durationMarks: TMarks = mdInstance.getDurationMarks(sliderStartupMileage)

    this.setState({
      durationSliderData: durationSliderData,
      mileageSliderData: mileageSliderData,
      mdInstance: mdInstance,
      mileageMarks: mileageMarks,
      durationMarks: durationMarks,
      sliderStartupDuration: sliderStartupDuration,
      sliderStartupMileage: sliderStartupMileage,
    })

    this.props.onChange(mileageSliderData, durationSliderData)
  }

  public render() {
    const { classes, freeContract, isMileageDisabled } = this.props
    const { isShowMessage, messageTrl, messageTitleTrl, mileageMarks, durationMarks } = this.state

    let { durationSliderData, mileageSliderData } = this.state

    const duration: IContractFlowMileageDuration = !durationSliderData
      ? this.getEmptyDurationSliderData()
      : durationSliderData
    const mileage: IContractFlowMileageDuration = !mileageSliderData
      ? this.getEmptyDurationSliderData()
      : mileageSliderData

    const { mileageValueFreeContract } = this.state
    const { handleDurationChange, handleMileageChange } = this
    const highMileageWarning = this.highMileageWarning()
    const isActive = this.isActive()

    const disableDurationSlider = !freeContract && duration.min === duration.max
    const disableMileageSlider = !freeContract && mileage.min === mileage.max

    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + '\n')
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'render(..): mileageMarks:')
    debugPrint(IS_DEBUG_SLIDER_PRINT, this.state.mileageMarks)

    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'render(..): durationMarks:')
    debugPrint(IS_DEBUG_SLIDER_PRINT, this.state.durationMarks)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + '\n')

    return (
      <>
        <Panel disabled={!isActive}>
          <PanelHeader>
            <PanelTitle>{t('Duration')}</PanelTitle>
          </PanelHeader>
          <PanelContent>
            <Card
              className={isActive ? classNames(classes.cardActive, freeContract && classes.freeContractActive) : ''}
              data-e2e={'ContractFlowDurationMileage2'}
            >
              <CardContent>
                {freeContract ? (
                  <TextField
                    fullWidth={true}
                    disabled={!isActive}
                    value={duration.value > 0 ? duration.value : ''}
                    margin="dense"
                    label={t('Select duration (months)')}
                    data-e2e={'ContractFlowDurationMileage2__freeContract-duration'}
                    // tslint:disable-next-line:jsx-no-lambda
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length <= 10) {
                        handleDurationChange(zeroOrNumber(e.target.value))
                      }
                    }}
                    type="number"
                  />
                ) : (
                  <>
                    <InputLabel className={classes.inputLabel}>
                      {!disableDurationSlider && t('Select duration (months)')}
                    </InputLabel>
                    <ContractFlowDurationMileageSlider
                      minLabel={duration ? `${duration.min} ${t('mos.')}` : '0'}
                      maxLabel={duration ? `${duration.max} ${t('mos.')}` : '60'}
                      selectedLabel={
                        <span data-e2e="ContractFlowDurationMileage2__freeContract-duration__labelValue">
                          {duration ? `${duration.value} ${t('months')}` : '0'}
                        </span>
                      }
                      convertedLabel={duration ? `${duration.value / 12} ${t('years')}` : `0 ${t('years')}`}
                      data={duration}
                      marks={durationMarks}
                      onChange={handleDurationChange}
                      disabled={!isActive || disableDurationSlider}
                    />
                  </>
                )}

                {!isMileageDisabled && (
                  <>
                    {freeContract ? (
                      <>
                        <TextField
                          fullWidth={true}
                          disabled={!isActive || (!duration && !mileageValueFreeContract)}
                          value={mileageValueFreeContract! > 0 ? mileageValueFreeContract : ''}
                          margin="dense"
                          InputProps={{
                            classes: {
                              underline: highMileageWarning ? classes.highMileageWarningInput : '',
                            },
                          }}
                          label={t('Select kilometers (in total)')}
                          data-e2e={'ContractFlowDurationMileage2__freeContract-mileage'}
                          // tslint:disable-next-line:jsx-no-lambda
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length <= 10) {
                              handleMileageChange(zeroOrNumber(e.target.value))
                            }
                          }}
                          type="number"
                        />
                        {highMileageWarning && (
                          <div className={classes.highMileageWarning}>
                            {t('Please note that you have chosen a very high mileage')}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <br />
                        <InputLabel className={classes.inputLabel}>
                          {!disableMileageSlider && t('Select kilometers (in total)')}
                        </InputLabel>
                        <ContractFlowDurationMileageSlider
                          minLabel={mileage ? `${formatMileage(mileage.min)} km` : '0 km'}
                          maxLabel={mileage ? `${formatMileage(mileage.max)} km` : `${formatMileage(10000)} km`}
                          selectedLabel={mileage.value ? `${formatMileage(mileage.value)} km` : '0 km'}
                          convertedLabel={
                            duration.value && mileage.value
                              ? this.estimatedMileagePerYear(mileage.value, duration.value)
                              : this.estimatedMileagePerYear(0, 0)
                          }
                          data={mileage}
                          marks={mileageMarks}
                          onChange={handleMileageChange}
                          disabled={!isActive || disableMileageSlider}
                        />
                      </>
                    )}
                  </>
                )}
              </CardContent>
            </Card>
          </PanelContent>
        </Panel>
        <ConfirmDialog
          open={isShowMessage}
          titleTrl={messageTitleTrl}
          contentTrlText={messageTrl}
          doHideCancelButton={true}
          onConfirm={() => {
            this.onConfirm()
          }}
          onCancel={() => {
            this.onConfirm()
          }}
        />
      </>
    )
  }

  private onConfirm = (): void => {
    this.setState({
      isShowMessage: false,
    })
  }

  private isActive = (): boolean => {
    const { freeContract, active, durationMileageValues, activeEnum } = this.props

    if (!durationMileageValues) return false

    const { duration, mileage } = durationMileageValues
    if (freeContract) {
      return active >= activeEnum
    } else {
      return duration.max > 0 || mileage.max > 0
    }
  }

  private handleDurationChange = (requestedDurationValue: number) => {
    console.debug('hdc')

    const currentDurationMonths: number = this.props.contractFlow.duration.value
    const { mdInstance, mileageSliderData, durationSliderData } = this.state
    debugPrint(
      IS_DEBUG_SLIDER_PRINT,
      PRE_STR + 'handleDurationChange(..): requestedDurationValue =' + requestedDurationValue,
    )

    if (requestedDurationValue === durationSliderData?.value) {
      return
    }
    if (!mileageSliderData) {
      throw Error('mileageSliderData is null')
    }
    if (!durationSliderData) {
      throw Error('durationSliderData is null')
    }

    const response: IKmMonths = mdInstance!.requestDurationOfMileage(requestedDurationValue, mileageSliderData.value)
    const responseDurationMonths: number = response.durationMonths

    if (IS_DEBUG_SLIDER_PRINT) {
      console.debug('responseDurationMonths (possible duration) = ' + responseDurationMonths)
      console.debug('currentDurationMonths (curent duration) = ' + currentDurationMonths)
    }
    if (responseDurationMonths === currentDurationMonths) {
      debugPrint(
        IS_DEBUG_SLIDER_PRINT,
        PRE_STR +
          `handleDurationChange(..): ...bailing setting new duration months (${responseDurationMonths}): It's already set (${currentDurationMonths})`,
      )
      return
    }

    const mileageKmData: IContractFlowMileageDuration = {
      ...mileageSliderData,
    }
    const durationMonthsData: IContractFlowMileageDuration = {
      ...durationSliderData,
      value: responseDurationMonths,
      wantedValue: responseDurationMonths,
    }

    this.props.onChange(mileageKmData, durationMonthsData)

    const lowerMin = mdInstance!.getAbsMinMaxDurations().min
    const upperMin = mdInstance!.getMinMaxDurations(mileageSliderData.value).min
    const lowerMax = mdInstance!.getMinMaxDurations(mileageSliderData.value).max
    const upperMax = mdInstance!.getAbsMinMaxDurations().max

    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'lowerMax = ' + lowerMax)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'upperMax = ' + upperMax)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'requestedDurationValue = ' + requestedDurationValue)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'responseDurationMonths = ' + responseDurationMonths)

    if (requestedDurationValue >= lowerMin && requestedDurationValue < upperMin) {
      this.setState({
        isShowMessage: true,
        messageTitleTrl: t(`This duration is not available with the current mileage`),
        // message: `This duration (months) is not available with selected mileage ${mileageSliderData.value} km — To select a lower duration, please choose another mileage first, and then reselect the new lower duration.`,
        messageTrl: t(
          'This duration (months) is not available with selected mileage %mileageSliderDataValue km — To select a lower duration, please choose another mileage first, and then reselect the new lower duration.',
          { mileageSliderDataValue: mileageSliderData.value },
        ),
      })
    }
    if (requestedDurationValue > lowerMax && requestedDurationValue <= upperMax) {
      this.setState({
        isShowMessage: true,
        messageTitleTrl: `This duration is not available with the current mileage`,
        // messageTrl: `This duration (months) is not available with selected mileage ${mileageSliderData.value} km — To select a higher duration, please choose another mileage first, and then reselect the new higher duration.`,
        messageTrl: t(
          `This duration (months) is not available with selected mileage %mileageSliderDataValue km — To select a higher duration, please choose another mileage first, and then reselect the new higher duration.`,
          { mileageSliderDataValue: mileageSliderData.value },
        ),
      })
    }
  }

  private handleMileageChange = (requestedMileageValue: number) => {
    console.debug('hmc')

    const currentMileageKm: number = this.props.contractFlow.mileage.value
    const { mdInstance, mileageSliderData, durationSliderData } = this.state
    debugPrint(
      IS_DEBUG_SLIDER_PRINT,
      PRE_STR + 'handleMileageChange(..): requestedMileageValue =' + requestedMileageValue,
    )

    if (requestedMileageValue === mileageSliderData?.value) {
      return
    }
    if (!mileageSliderData) {
      throw Error('mileageSliderData is null')
    }
    if (!durationSliderData) {
      throw Error('durationSliderData is null')
    }

    const response: IKmMonths = mdInstance!.requestMileageOfDuration(requestedMileageValue, durationSliderData.value)
    const responseMileageKm: number = response.mileageKm

    if (IS_DEBUG_SLIDER_PRINT) {
      console.debug('responseMileageKm (possible duration) = ' + responseMileageKm)
      console.debug('currentMileageKm (curent duration) = ' + currentMileageKm)
    }
    if (responseMileageKm === currentMileageKm) {
      debugPrint(
        IS_DEBUG_SLIDER_PRINT,
        PRE_STR +
          `handleMileageChange(..): ...bailing setting new mileage km (${responseMileageKm}): It's already set (${currentMileageKm})`,
      )
      return
    }

    const mileageKmData: IContractFlowMileageDuration = {
      ...mileageSliderData,
      value: responseMileageKm,
      wantedValue: responseMileageKm,
    }
    const durationMonthsData: IContractFlowMileageDuration = {
      ...durationSliderData,
    }

    this.props.onChange(mileageKmData, durationMonthsData)

    const lowerMin = mdInstance!.getAbsMinMaxMileages().min
    const upperMin = mdInstance!.getMinMaxMileages(durationSliderData.value).min
    const lowerMax = mdInstance!.getMinMaxMileages(durationSliderData.value).max
    const upperMax = mdInstance!.getAbsMinMaxMileages().max

    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'lowerMin = ' + lowerMin)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'upperMin = ' + upperMin)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'requestedMileageValue = ' + requestedMileageValue)
    debugPrint(IS_DEBUG_SLIDER_PRINT, PRE_STR + 'responseMileageKm = ' + responseMileageKm)

    if (requestedMileageValue >= lowerMin && requestedMileageValue < upperMin) {
      this.setState({
        isShowMessage: true,
        messageTitleTrl: t(`This mileage is not available with the current duration`),
        // messageTrl: t(`This mileage (km) is not available with the selected duration of ${durationSliderData.value} months. — To select a lower mileage, please choose another duration first, and then reselect the new lower mileage.`),
        messageTrl: t(
          `This mileage (km) is not available with the selected duration of %durationSliderDataValue months. — To select a lower mileage, please choose another duration first, and then reselect the new lower mileage.`,
          { durationSliderDataValue: durationSliderData.value },
        ),
      })
    }
    if (requestedMileageValue > lowerMax && requestedMileageValue <= upperMax) {
      this.setState({
        isShowMessage: true,
        messageTitleTrl: t(`This mileage is not available with the current duration`),
        // messageTrl: t(`This mileage (km) is not available with the selected duration of ${durationSliderData.value} months — To select a higher mileage, please choose another duration first, and then reselect the new higher mileage.`),
        messageTrl: t(
          `This mileage (km) is not available with the selected duration of %durationSliderDataValue months — To select a higher mileage, please choose another duration first, and then reselect the new higher mileage.`,
          { durationSliderDataValue: durationSliderData.value },
        ),
      })
    }
  }

  private highMileageWarning = () => {
    const { durationMileageValues } = this.props

    return durationMileageValues?.mileage.value > defaultHighMilegaWarningLimit
  }

  private estimatedMileagePerYear = (mileage: number, duration: number): string => {
    let convertedMileage = 0

    if (duration > 0) {
      const years = duration / 12
      convertedMileage = Math.round(mileage / years)
    }

    return `${t('Estimated %mileage km/year', { mileage: formatMileage(convertedMileage) })}`
  }
}

export default withStyles(styles)(ContractFlowDurationMileageVer2)
