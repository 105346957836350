import { CircularProgress } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { formatDate } from '@omnicar/sam-format'
import { IJsonStatus } from '@omnicar/sam-tfetch'
import {
  ApiError,
  ContractState,
  CustomerUpdateResponse,
  IAdminCustomer,
  IBasicContractInfo,
  IContractCalculationRequest,
  IContractCalculationResponse,
  IContractCreationData,
  IContractDetailsRecord,
  ICustomContractCalculationRequest,
  IStandardAxContractCalculationRequest,
  IStandardContractCalculationRequest,
  IWorkshopOperationCollection,
  Other,
  Vehicle,
} from '@fragus/sam-types'
import { SentryEvent, Severity } from '@sentry/types'
import ActionTypes from 'actions/ActionTypes'
import {
  IContractDisplayConfigSetHiddenVAT,
  setHiddenVAT,
  IContractDisplayConfigSetShowMileageInfo,
  setShowMileageInfo,
} from 'actions/contractDisplayConfigActions'
import {
  calculateContractOffer,
  changeContractCustomerToEntireNewCustomer,
  changeContractCustomerToExistingCustomer,
  getContractBasicInfo,
  getContractBasicInfoFromReportLink,
  getContractCreationData,
  getContractCustomer,
  getContractDetailsFromReportLink,
  getContractProduct,
  getContractSpecifics,
  getStatusOfMinimumPaymentsCount,
  patchContractCustomerData,
} from 'api/api'
import ContractDetailsActionButtons from 'components/admin/Contract/Details/ActionButtons'
import { Page, PageActions, PageContent, PageHeader, PageNavigation, PageTitle } from 'components/Mui/Page'
import { ITab } from 'components/Mui/Page/PageNavigation'
import { CONFIG } from 'config'
import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import { compose } from 'recompose'
import {
  contractDetailsSettlePath,
  getContractDetailsEconomyPath,
  getContractDetailsPath,
  pageNotFoundPath,
  reportContractDetailsEconomyPath,
  reportContractDetailsPath,
  stripePaymentPath,
  unAuthorizedPath,
} from 'routes/paths'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import { checkWhetherHideVAT } from 'utils/contract'
import browserHistory from 'utils/history'
import { getProvider, getRole } from 'utils/localStorage'
import notify from 'utils/notify/notify'
import { captureEvent } from 'utils/sentry'
import {
  ILookupCustomerWithEmailResponse,
  lookupCustomerStatusOfEmail,
} from '../../../components/admin/Contract/Flow/Customer/Form/index'
import ConfirmDialog from '../../../components/ConfirmDialog'
import ExpirationDialog from './ExpirationDialog'
import { EconomyTab, GeneralTab, SettleTab } from './Tabs'
import Title from './Title'

interface IRouteProps {
  prettyIdentifier: string
}

interface IReduxDispatchProps {
  setHiddenVAT: (hiddenVAT: boolean) => IContractDisplayConfigSetHiddenVAT
  setShowMileageInfo: (showMileageInfo: boolean) => IContractDisplayConfigSetShowMileageInfo
}

type Tab = 'GENERAL' | 'ECONOMY' | 'SETTLE'
type TProps = RouteComponentProps<IRouteProps> & WithStyles<typeof styles> & IReduxDispatchProps

interface IState {
  basicInfo: IBasicContractInfo | undefined
  invalidContract: boolean
  unAuthorized: boolean
  workshopOperationsUpdateCounter: number
  contractSpecifics: IContractDetailsRecord
  tooFewPayments: boolean
  isContractSpecificsLoading: boolean
  vehicleRecord: Vehicle | Other
  productRecordLoading: boolean
  customerRecord: IAdminCustomer
  purchaseDialogOpen: boolean
  contractCalculationResponse?: IContractCalculationResponse
  creationData?: IContractCreationData
  isPaid?: boolean
  isCreating?: boolean
  activateContractFail?: boolean
  gettingContractPaymentData: boolean
  isFromReportLink: boolean
  isSameProvider: boolean
  serviceCount?: number | undefined
  isSettleMessageShown: boolean
  lockedSettleTab: boolean
  settlementOperationAmount: number
  isChangeToAnotherCustomerDialogOpen: boolean
  isForeignCustomerExistsDialogOpen: boolean
  isExistAsUserDialogOpen: boolean
  isChangeToEntireNewCustomerDialogOpen: boolean
  updateContractCustomerResponse?: ILookupCustomerWithEmailResponse
}

const styles = (theme: Theme) =>
  createStyles({
    hide: {
      display: 'none',
    },
    settleContainerLeft: {},
    settleContainerRight: {
      flexBasis: '65%',
    },
    descWrapper: {
      width: theme.spacing(50),
    },
    lockedSettleTab: {
      position: 'relative',
      pointerEvents: 'none',
      opacity: 0.5,
    },
    initDataProgress: {
      paddingTop: '20vh',
      paddingLeft: '10vw',
    },
  })

class ContractDetailsPage extends React.Component<TProps, IState> {
  public state: IState = {
    basicInfo: undefined,
    invalidContract: false,
    unAuthorized: false,
    workshopOperationsUpdateCounter: 0,
    isContractSpecificsLoading: false,
    contractSpecifics: {
      contractType: 'STANDARD',
      contractProductType: null,
      contractName: '',
      contractTemplateDescription: '',
      serviceVariantId: '',
      duration: 0,
      mileage: 0,
      startDate: '',
      expirationDate: '',
      stripeCustomerLink: null,
      stripeSubscriptionLink: null,
      totalPrice: {
        price: 0,
        priceInclVat: 0,
        vatShare: 0,
        currency: '',
      },
      monthlyPrice: {
        price: 0,
        priceInclVat: 0,
        vatShare: 0,
        currency: '',
      },
      providerShare: {
        price: 0,
        priceInclVat: 0,
        vatShare: 0,
        currency: '',
      },
      monthlyTemplatePrice: {
        price: 0,
        priceInclVat: 0,
        vatShare: 0,
        currency: '',
      },
      value: 0,
      valueType: 'Mileage',
      startValue: 0,
      startValueType: 'Mileage',
      reference: '',
      seller: undefined,
      startMileage: 0,
      endMileage: 0,
      extendedFrom: null,
      adjustedTo: undefined,
      includedOptions: [],
      prettyIdentifierShort: '',
      prettyIdentifier: '',
      termsPdfUrl: '',
      termsOfTradePdfUrl: '',
      contractPdfUrl: '',
      filesContainerAlongContract: null,
      paymentMethod: '' as any,
      customTerms: '',
      providerPayments: 0,
      contractTemplateId: 0,
      priceSource: 'Pricelist',
      contractTemplateIsArchived: true,
      contractTemplateMinPaymentsCount: 0,
      flags: [],
      calculationMethod: 100,
      settledUnits: null,
      underdrivenCharge: undefined,
      overdrivenCharge: undefined,
      createdByProvider: '',
      provider: '',
      isProduct: false,
    },
    vehicleRecord: {
      vin: '',
      regNumber: '',
      regDate: '',
      modelYear: 0,
      brand: {
        name: '',
      },
      model: {
        name: '',
      },
      fuelType: {
        name: '',
      },
      engineMaxPower: undefined,
      vehicleType: undefined,
    },
    customerRecord: {
      name: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      zip: '',
      customerType: 'PRIVATE',
    },
    tooFewPayments: false,
    productRecordLoading: false,
    purchaseDialogOpen: false,
    contractCalculationResponse: undefined,
    creationData: undefined,
    isPaid: false,
    isCreating: false,
    activateContractFail: false,
    gettingContractPaymentData: false,
    serviceCount: undefined,
    isFromReportLink: false,
    isSameProvider: false,
    isSettleMessageShown: false,
    lockedSettleTab: false,
    settlementOperationAmount: 0,
    isChangeToAnotherCustomerDialogOpen: false,
    isForeignCustomerExistsDialogOpen: false,
    isChangeToEntireNewCustomerDialogOpen: false,
    isExistAsUserDialogOpen: false,
  }

  /**
   * Note: Using and having this method instead of a class property makes the title value
   * translatable when switching locale.
   */
  private getHeaderTabs = (isServiceAgreement: boolean): Array<ITab<Tab>> => {
    const { basicInfo } = this.state
    if (isServiceAgreement) {
      const showSettlement =
        basicInfo &&
        [
          ContractState.CancelledSubscription,
          ContractState.Terminated,
          ContractState.Expired,
          ContractState.Settled,
        ].includes(basicInfo.contractState)

      const mainTabs = [{ title: t('General'), name: 'GENERAL' }] as ITab<Tab>[]
      basicInfo &&
        ![
          ContractState.Offer,
          ContractState.OfferExpired,
          ContractState.OfferRejected,
          ContractState.OfferRevoked,
        ].includes(basicInfo.contractState) &&
        mainTabs.push({ title: t('Statistics'), name: 'ECONOMY' })

      return showSettlement ? [...mainTabs, { title: t('Settlement'), name: 'SETTLE' }] : mainTabs
    } else {
      const mainTabs = [{ title: t('General'), name: 'GENERAL' }] as ITab<Tab>[] // Note: Warranty tab

      basicInfo &&
        ![
          ContractState.Offer,
          ContractState.OfferExpired,
          ContractState.OfferRejected,
          ContractState.OfferRevoked,
        ].includes(basicInfo.contractState) &&
        mainTabs.push({ title: t('Statistics'), name: 'ECONOMY' })

      return mainTabs
    }
  }

  //basicInfo && basicInfo.contractState ? [100, 110, 120, 130].includes(basicInfo.contractState)

  // private getHeaderTabs = (isServiceAgreement: boolean): Array<ITab<Tab>> => {
  //   if (isServiceAgreement) {
  //     const { basicInfo } = this.state
  //     const showSettlement =
  //       basicInfo &&
  //       [
  //         ContractState.CancelledSubscription,
  //         ContractState.Terminated,
  //         ContractState.Expired,
  //         ContractState.Settled,
  //       ].includes(basicInfo.contractState)
  //     const mainTabs = [
  //       { title: t('General'), name: 'GENERAL' },
  //       { title: t('Statistics'), name: 'ECONOMY' },
  //     ] as ITab<Tab>[]
  //     return showSettlement ? [...mainTabs, { title: t('Settlement'), name: 'SETTLE' }] : mainTabs
  //   } else {
  //     return [
  //       { title: t('General'), name: 'GENERAL' },
  //       { title: t('Statistics'), name: 'ECONOMY' },
  //     ] // Note: Warranty tab
  //   }
  // }

  private initData = async () => {
    const contractPrettyId = this.props.match.params.prettyIdentifier
    const response = await this.getContractBasicInfo(contractPrettyId)
    let unAuthorized = !!(response.statusCode && response.statusCode === 401) || this.state.unAuthorized

    const result = await getContractCustomer(contractPrettyId)
    const customer: IAdminCustomer = result.data!

    if (response.data) {
      this.setState({
        basicInfo: response.data,
        unAuthorized: false,
        customerRecord: customer,
      })
      this.initTooFewPayments()
    } else if (response.statusCode && response.statusCode === 404) {
      this.setState({ invalidContract: true, unAuthorized })
    }
  }

  public componentDidMount() {
    this.setSameProvider()
    const isFromReportLink = this.isFromLink()
    this.setState({ isFromReportLink }, async () => {
      this.initData()
      this.initTooFewPayments()
      this.setState({
        workshopOperationsUpdateCounter: this.state.workshopOperationsUpdateCounter + 1,
      })
    })
  }

  static getDerivedStateFromProps(nextProps: TProps, prevState: IState) {
    // If the contract id changes, set the value of tooFewPayments to false.
    // Because tooFewPayments=true for the wrong contract causes irrelevant API requests(that might cause 400 Error).
    if (
      prevState.contractSpecifics.prettyIdentifier !== nextProps.match.params.prettyIdentifier &&
      prevState.tooFewPayments
    ) {
      return { tooFewPayments: false }
    }

    return null
  }

  public componentDidUpdate(prevProps: TProps, prevState: IState) {
    const isFromReport = this.matchesReportLinkURL(this.props.match.params.prettyIdentifier)

    if (
      this.props.match.params.prettyIdentifier !== prevProps.match.params.prettyIdentifier ||
      this.state.isFromReportLink !== isFromReport
    ) {
      // We have to know if this contract url is from a
      // report link before we change up the render
      // or the 404 will be displayed for any other provider contracts
      this.setState({ isFromReportLink: isFromReport }, () => {
        this.reset()
      })
    }

    if (
      prevState.contractSpecifics.contractTemplateMinPaymentsCount !==
      this.state.contractSpecifics.contractTemplateMinPaymentsCount
    ) {
      this.initTooFewPayments()
    }
  }

  public componentWillUnmount() {
    this.props.setHiddenVAT(false)
  }

  public render() {
    const { classes } = this.props
    const {
      basicInfo,
      invalidContract,
      contractSpecifics,
      gettingContractPaymentData,
      unAuthorized,
      vehicleRecord,
      customerRecord,
    } = this.state
    if (invalidContract) {
      return <Redirect to={pageNotFoundPath} />
    }

    if (unAuthorized) {
      return <Redirect to={unAuthorizedPath} />
    }

    const { contractType, contractTemplateIsArchived, monthlyTemplatePrice } = contractSpecifics
    const { brand, model, fuelType } = vehicleRecord
    const {
      isFromReportLink,
      isSameProvider,
      tooFewPayments,
      isChangeToAnotherCustomerDialogOpen,
      isForeignCustomerExistsDialogOpen,
      isExistAsUserDialogOpen,
      isChangeToEntireNewCustomerDialogOpen,
      updateContractCustomerResponse,
      productRecordLoading,
      isContractSpecificsLoading,
      serviceCount,
      isSettleMessageShown,
      workshopOperationsUpdateCounter,
    } = this.state

    const buyNowDisabled =
      contractTemplateIsArchived ||
      (contractType === 'STANDARD' &&
        !brand.id &&
        !model.id &&
        (!fuelType || (!fuelType.id && !fuelType.name)) &&
        !brand.name &&
        !model.name) ||
      (contractType === 'CUSTOM' && !monthlyTemplatePrice.priceInclVat) ||
      contractSpecifics.totalPrice.price === contractSpecifics.providerShare.price

    const isServiceAgreement = contractType === 'STANDARD' || contractType === 'CUSTOM'
    const isExternalWarranty = contractType === 'EXTERNAL'
    const enabledTabs = this.getHeaderTabs(isServiceAgreement)

    const isReadOnly = isFromReportLink && !isSameProvider
    const id = this.props.match.params.prettyIdentifier

    const toRequestedCustomer: IAdminCustomer | undefined = updateContractCustomerResponse?.customer
    const userRole = getRole()

    if (!basicInfo) {
      return (
        <div className={classes.initDataProgress}>
          <CircularProgress size={32} color="secondary" />
        </div>
      )
    }

    return (
      <Page>
        <PageHeader>
          <PageTitle>
            <Title id={id} basicInfo={basicInfo} />
          </PageTitle>
          <PageNavigation active={this.getActiveTab()} onChange={this.handleTabChange} tabs={enabledTabs} />
          {!isReadOnly && (isServiceAgreement || isExternalWarranty) && (
            <PageActions>
              <AppContext.Consumer>
                {({ locale, isSuperAdmin, role, providerInfo }) =>
                  role !== 'observer' && (
                    <ContractDetailsActionButtons
                      key={locale}
                      basicInfo={basicInfo}
                      contractVersionCallback={this.contractVersionUpdated}
                      reloadContractInfo={this.reset}
                      id={id}
                      status={basicInfo && basicInfo.contractState}
                      contractType={basicInfo && basicInfo.contractType}
                      paymentGateway={basicInfo && basicInfo.paymentGateway}
                      buyNowDisabled={buyNowDisabled}
                      initContractPayment={this.initContractPayment}
                      gettingContractPaymentData={gettingContractPaymentData}
                      customer={customerRecord}
                      tooFewPayments={tooFewPayments}
                      isSuperAdmin={isSuperAdmin}
                      canCopyType={
                        (providerInfo && providerInfo.productsEnabled) || (basicInfo && !basicInfo.productContract)
                      }
                      onSettleMessageHide={this.handleInfoMessageHide}
                      onSettleMessageShow={this.handleInfoMessageShow}
                      settledUnits={contractSpecifics.settledUnits}
                      handleTabForSettleButton={this.handleTabForSettleButton}
                      isSettlementTab={this.getActiveTab() === 'SETTLE'}
                      expirationDate={contractSpecifics.expirationDate}
                    />
                  )
                }
              </AppContext.Consumer>
            </PageActions>
          )}
        </PageHeader>
        <PageContent>
          {/* TODO: investigate workshop data updates/sync refactoring in TABS components */}
          {basicInfo && (
            <Switch>
              <Route
                exact={true}
                path={getContractDetailsPath(isFromReportLink, id)}
                children={
                  <GeneralTab
                    customerRecord={customerRecord}
                    contractPrettyId={id}
                    contractState={basicInfo.contractState}
                    contractStateName={basicInfo.contractStateName}
                    isContractExternalWarranty={basicInfo.contractState === 210}
                    isReadOnly={isReadOnly || userRole === 'observer'}
                    vehicleRecord={vehicleRecord}
                    productRecordLoading={productRecordLoading}
                    initVehicleRecord={this.initVehicleRecord}
                    contractSpecifics={contractSpecifics}
                    isContractSpecificsLoading={isContractSpecificsLoading}
                    initContractSpecifics={this.initContractSpecifics}
                    isFromReportLink={isFromReportLink}
                    handleCustomerPatch={this.handlePatchWithContractCustomerUpdateOrChange}
                    handleUpdateCustomerDetailsState={this.handleUpdateCustomerDetailsState}
                  />
                }
              />

              <Route
                exact={true}
                path={getContractDetailsEconomyPath(isFromReportLink, id)}
                children={
                  <EconomyTab
                    contractPrettyId={id}
                    workshopOperationsUpdateCounter={workshopOperationsUpdateCounter}
                    contractSpecifics={contractSpecifics}
                    contractState={basicInfo.contractState}
                    isReadOnly={isReadOnly || userRole === 'observer'}
                    onWorkshopListUpdate={this.onWorkshopListUpdate}
                    handleWorkshopOperationsListChange={this.handleWorkshopOperationsListChange}
                    serviceCount={serviceCount}
                    isFromReportLink={isFromReportLink}
                  />
                }
              />
              <Route
                exact={true}
                path={contractDetailsSettlePath()}
                children={
                  <SettleTab
                    contractPrettyId={id}
                    contractState={basicInfo.contractState}
                    isSettleMessageShown={isSettleMessageShown}
                    userRole={userRole}
                    contractSpecifics={contractSpecifics}
                    serviceCount={serviceCount}
                    workshopOperationsUpdateCounter={workshopOperationsUpdateCounter}
                    isReadOnly={isReadOnly || userRole === 'observer'}
                    initContractSpecifics={this.initContractSpecifics}
                    customerName={customerRecord.name}
                  />
                }
              />
            </Switch>
          )}
          <ExpirationDialog prettyIdentifier={id} contractState={basicInfo.contractState} />
          <ConfirmDialog
            open={isChangeToAnotherCustomerDialogOpen}
            titleTrl={t('The customer already exists. Do you want to update/change to this customer instead?')}
            contentTrlText={
              <>
                <label>{t('Customer Email')}:</label>
                <br />
                <b>{toRequestedCustomer?.email}</b> <br />
                <br />
                <label>{t('Customer')}:</label>
                <br />
                <b>
                  {toRequestedCustomer?.name} <br />
                  {toRequestedCustomer?.address} <br />
                  {toRequestedCustomer?.zip} {toRequestedCustomer?.city}
                </b>
                <br />
              </>
            }
            captionTrlConfirmButton={t('Yes')}
            onConfirm={() => {
              const contractIdent: string = id
              this.closeAllDialogs(async () => {
                const { data } = await this.changeToAnotherCustomerOnContract(contractIdent, toRequestedCustomer!)

                // Updates change-To-Another-Customer info here.
                this.setState({ customerRecord: data! })
              })
            }}
            captionTrlCancelButton={t('No')}
            onCancel={() => {
              this.closeAllDialogs(() => this.restoreCustomerDetails())
            }}
          />
          <ConfirmDialog
            open={isForeignCustomerExistsDialogOpen}
            titleTrl={t('This email address cannot be used since the customer is related to a different company.')}
            captionTrlConfirmButton={t('OK')}
            onConfirm={() => {
              this.closeAllDialogs()
            }}
            doHideCancelButton={true}
          />
          <ConfirmDialog
            open={isExistAsUserDialogOpen}
            titleTrl={t('This email address cannot be used because it is already in use.')}
            captionTrlConfirmButton={t('OK')}
            onConfirm={() => {
              this.closeAllDialogs()
            }}
            doHideCancelButton={true}
          />
          <ConfirmDialog
            open={isChangeToEntireNewCustomerDialogOpen}
            titleTrl={t(
              'There is no customer with this email address. Add (register) this email address as a new customer and transfer this contract to them?',
            )}
            contentTrlText={
              <>
                <label>{t('Customer Email')}:</label>
                <br />
                <b>{customerRecord?.email}</b> <br />
                <br />
                <label>{t('Customer')}:</label>
                <br />
                <b>
                  {customerRecord?.name} <br />
                  {customerRecord?.address} <br />
                  {customerRecord?.zip} {customerRecord?.city}
                </b>
                <br />
              </>
            }
            captionTrlConfirmButton={t('Yes, Add Customer')}
            onConfirm={() => {
              const contractIdent: string = id

              this.closeAllDialogs(async () => {
                const { data } = await this.changeToEntireNewCustomerOnContract(contractIdent, customerRecord!)

                // Updates change-To-Another-Customer info here.
                this.setState({ customerRecord: data! })
              })
            }}
            captionTrlCancelButton={t('No')}
            onCancel={() => {
              this.closeAllDialogs(() => this.restoreCustomerDetails())
            }}
          />
        </PageContent>
      </Page>
    )
  }

  private closeAllDialogs = (callback?: () => void) => {
    this.setState(
      {
        isChangeToAnotherCustomerDialogOpen: false,
        isForeignCustomerExistsDialogOpen: false,
        isExistAsUserDialogOpen: false,
        isChangeToEntireNewCustomerDialogOpen: false,
      },
      callback,
    )
  }

  private restoreCustomerDetails = async () => {
    const contractPrettyId = this.state.contractSpecifics.prettyIdentifier
    const result = await getContractCustomer(contractPrettyId)
    const customer: IAdminCustomer = result.data!

    this.setState({
      customerRecord: customer,
    })
  }

  /**
   * @param editedCustomerRecord Holds the fresh and updated (new) info on the contract.
   * @param initialCustomerRecord Holds the current (old) info and customer on the contract.
   * @returns
   */
  private handlePatchWithContractCustomerUpdateOrChange = async (
    contractIdent: string,
    editedCustomerRecord: IAdminCustomer,
    initialCustomerRecord: IAdminCustomer,
  ): Promise<IJsonStatus<CustomerUpdateResponse, ApiError> | undefined> => {
    const newEmail: string = editedCustomerRecord.email
    const oldEmail: string = initialCustomerRecord.email

    const isEditingSameCustomer: boolean = newEmail.trim().toLowerCase() === oldEmail.trim().toLowerCase()
    const lookupResponse: ILookupCustomerWithEmailResponse = await lookupCustomerStatusOfEmail(
      newEmail,
      'Edit-customer-email-on-contract-mode',
    )

    let updateResponse: IJsonStatus<CustomerUpdateResponse, ApiError> = { data: undefined, errorData: undefined }
    switch (lookupResponse && lookupResponse.customerError) {
      case 'CUSTOMER_EXISTS':
        if (isEditingSameCustomer) {
          this.closeAllDialogs()
          updateResponse = await this.updateCustomerDataOnContract(contractIdent, editedCustomerRecord)
        } else {
          // Show confirm dialog.
          this.setState({
            isChangeToAnotherCustomerDialogOpen: true,
            updateContractCustomerResponse: lookupResponse,
          })
        }

        break
      case 'FOREIGN_CUSTOMER_EXISTS':
        // Show ok dialog.
        this.setState({ isForeignCustomerExistsDialogOpen: true })
        break
      case 'CUSTOMER_EXIST_AS_USER':
        // Show ok dialog.
        this.setState({ isExistAsUserDialogOpen: true })
        break
      default:
        // const isExternalContract: boolean = this.state.contractSpecifics.contractType === 'EXTERNAL'

        if (lookupResponse.statusCode === 404 && lookupResponse.isValidEmail) {
          // Commented out if-else below at 2023-12-07
          // if (isExternalContract) {
          editedCustomerRecord.id = undefined // Invalidate previous id, this is not correct anymore.
          this.setState({
            isChangeToEntireNewCustomerDialogOpen: true,
            customerRecord: editedCustomerRecord,
          })
          // } else {
          //   notify.error({
          //     message:
          //       'No customer with this email was found. On subscription contracts, cannot update customer to a non existing customer',
          //   })
          // }
        } else {
          console.warn('..UpdateOrChange(..): Ignoring action to be made due to non-external contract')
        }
    }

    return updateResponse
  }

  private updateCustomerDataOnContract = async (
    contractIdent: string,
    record: IAdminCustomer,
  ): Promise<IJsonStatus<CustomerUpdateResponse, ApiError>> => {
    // Update information on the customer on this contract.
    const statusResponse: IJsonStatus<CustomerUpdateResponse, ApiError> = await patchContractCustomerData(
      contractIdent,
      record,
    )

    if (statusResponse.statusCode !== 200) {
      console.error(statusResponse)
      notify.error({
        message:
          t('Something went wrong') +
          ', when calling patchContractCustomerData(..), code: ' +
          statusResponse.statusCode,
      })
    }

    return statusResponse
  }

  /**
   * "Transfer" contract to another customer (determined by the email address).
   */
  private changeToAnotherCustomerOnContract = async (
    contractIdent: string,
    toRequestedCustomer: IAdminCustomer,
  ): Promise<IJsonStatus<CustomerUpdateResponse, ApiError>> => {
    const { customerRecord } = this.state

    // Check if the email address is already in use
    const lookupResponse: ILookupCustomerWithEmailResponse = await lookupCustomerStatusOfEmail(
      toRequestedCustomer.email,
      'Edit-customer-email-on-contract-mode',
    )

    const newCustomerId: number = lookupResponse!.customer!.id!
    const oldCustomerId: number = customerRecord.id!

    // Change customer on the contract to an existing customer.
    const statusResponse: IJsonStatus<
      CustomerUpdateResponse,
      ApiError
    > = await changeContractCustomerToExistingCustomer(contractIdent, newCustomerId, oldCustomerId)

    if (statusResponse.statusCode === 200) {
      notify.success({
        message: t('The customer on this contract was changed to another existing customer') + '.',
      })
    } else {
      console.error(statusResponse)
      notify.error({
        message:
          t('Something went wrong') +
          ', when calling patchContractCustomerToExistingCustomer(..), code: ' +
          statusResponse.statusCode,
      })
    }

    return statusResponse
  }

  /**
   * "Transfer" contract to another customer (determined by the email address).
   */
  private changeToEntireNewCustomerOnContract = async (
    contractIdent: string,
    toRequestedCustomer: IAdminCustomer,
  ): Promise<IJsonStatus<CustomerUpdateResponse, ApiError>> => {
    // Check if the email address is already in use
    // const lookupResponse: ILookupCustomerWithEmailResponse = await lookupCustomerStatusOfEmail(
    //   toRequestedCustomer.email,
    //   'Edit-customer-email-on-contract-mode',
    // )

    // Change customer on the contract to an existing customer.
    const statusResponse: IJsonStatus<
      CustomerUpdateResponse,
      ApiError
    > = await changeContractCustomerToEntireNewCustomer(contractIdent, toRequestedCustomer)

    if (statusResponse.statusCode === 200) {
      notify.success({
        message: t('The customer on this contract was changed to an entirely new customer') + '.',
      })
    } else {
      console.error(statusResponse)
      notify.error({
        message:
          t('Something went wrong') +
          ', when calling changeToNewCustomerOnContract(..), code: ' +
          statusResponse.statusCode,
      })
    }

    return statusResponse
  }

  // Checks if the url matches either the contract details or the details economy path
  // meant for report links.
  private isFromLink = () => {
    const id = this.props.match.params.prettyIdentifier
    const matchesReportLinkURL = this.matchesReportLinkURL(id)
    return matchesReportLinkURL
  }

  private setSameProvider = () => {
    const id = this.props.match.params.prettyIdentifier

    const wantedProvider = Number(id.split('-')[0] || Number.NaN)
    const provider = getProvider()
    const isSameProvider = !!provider && wantedProvider === provider.providerId
    this.setState({ isSameProvider })
  }

  private matchesReportLinkURL = (id: string) => {
    return (
      this.props.location.pathname === reportContractDetailsEconomyPath(id) ||
      this.props.location.pathname === reportContractDetailsPath(id)
    )
  }

  private handleWorkshopOperationsListChange = (operations: IWorkshopOperationCollection[]) => {
    const newCount = operations.reduce((c, o) => {
      if (o.date >= this.state.contractSpecifics.startDate) c += o.operations.length
      return c
    }, 0)

    if (this.state.serviceCount !== newCount) {
      this.setState({ serviceCount: newCount })
    }
  }

  private reset = async () => {
    this.setState({ basicInfo: undefined })
    await this.initData()
    await this.initVehicleRecord()
    await this.initContractSpecifics()
    await this.initTooFewPayments()
  }

  private initContractSpecifics = async () => {
    const prettyId = this.props.match.params.prettyIdentifier
    this.setState({ isContractSpecificsLoading: true })

    const responseContractSpecifics = await this.getContractSpecifics(prettyId)

    let unAuthorized =
      !!(responseContractSpecifics.statusCode && responseContractSpecifics.statusCode === 401) ||
      this.state.unAuthorized

    if (responseContractSpecifics && responseContractSpecifics.data) {
      this.setState(
        {
          contractSpecifics: responseContractSpecifics.data,
          isContractSpecificsLoading: false,
          unAuthorized: false,
        },
        this.updateHiddenVAT,
      )
    } else {
      this.setState({
        isContractSpecificsLoading: false,
        unAuthorized,
      })
    }
  }

  private getContractSpecifics = async (prettyId: string) => {
    return await (this.state.isFromReportLink
      ? getContractDetailsFromReportLink(prettyId)
      : getContractSpecifics(prettyId))
  }

  private handleUpdateCustomerDetailsState = (customerRecord: IAdminCustomer) => {
    this.setState({ customerRecord: customerRecord })
  }

  private initVehicleRecord = async () => {
    const id = this.props.match.params.prettyIdentifier
    this.setState({ productRecordLoading: true })
    const responseProductRecord = await getContractProduct(id)

    if (responseProductRecord && responseProductRecord.data) {
      this.setState({
        vehicleRecord: responseProductRecord.data,
        productRecordLoading: false,
      })

      // Show mileage info only for vehicles that are not caravans. Set when the vehicle is fetched.
      const vehicle = responseProductRecord.data as Vehicle
      this.props.setShowMileageInfo(vehicle.vehicleType !== 'Caravan')
    } else {
      this.setState({
        productRecordLoading: false,
      })
    }
  }

  private getContractBasicInfo = async (id: string) => {
    return await (this.state.isFromReportLink ? getContractBasicInfoFromReportLink(id) : getContractBasicInfo(id))
  }

  private initTooFewPayments = async () => {
    const { contractSpecifics, basicInfo } = this.state
    const id = this.props.match.params.prettyIdentifier
    let tooFewPayments = false

    if (
      contractSpecifics &&
      basicInfo &&
      basicInfo.contractState === ContractState.Active &&
      contractSpecifics.contractTemplateMinPaymentsCount
    ) {
      const response = await getStatusOfMinimumPaymentsCount(id)
      tooFewPayments = response.statusCode === 200 && !!response.data
    }

    this.setState({ tooFewPayments })
  }

  private handleTabChange = (tab: string) => {
    const targetTab = tab as Tab
    if (targetTab !== this.getActiveTab()) {
      const newPath = this.getTabPath(targetTab)
      browserHistory.replace(newPath)
    }
  }

  private getActiveTab = () => {
    const { pathname } = this.props.location
    const { prettyIdentifier } = this.props.match.params
    const { isFromReportLink } = this.state
    return pathname === getContractDetailsEconomyPath(isFromReportLink, prettyIdentifier)
      ? 'ECONOMY'
      : pathname === contractDetailsSettlePath(prettyIdentifier)
      ? 'SETTLE'
      : 'GENERAL'
  }

  private getTabPath = (tab: Tab): string => {
    const { prettyIdentifier } = this.props.match.params
    const { isFromReportLink } = this.state

    switch (tab) {
      case 'GENERAL':
        return getContractDetailsPath(isFromReportLink, prettyIdentifier)
      case 'ECONOMY':
        return getContractDetailsEconomyPath(isFromReportLink, prettyIdentifier)
      case 'SETTLE':
        return contractDetailsSettlePath(prettyIdentifier)
    }
  }

  private contractVersionUpdated = () => this.reset()

  private calculateDownpayment = async () => {
    const { contractSpecifics, vehicleRecord } = this.state
    const {
      contractType,
      contractTemplateId,
      duration,
      mileage,
      includedOptions,
      providerPayments,
      providerShare,
      adjustedFrom,
      flags,
      monthlyTemplatePrice,
      value,
      valueType,
      startValueType,
      contractProductType,
    } = contractSpecifics
    const { regDate, brand, model, fuelType } = vehicleRecord

    const optionIds: number[] = []
    includedOptions &&
      includedOptions.forEach((option) => {
        if (option.assoc === 'ADDITIONAL') {
          optionIds.push(option.optionId)
        }
      })

    const baseOffer: IContractCalculationRequest = {
      type: contractType,
      contractProductType: contractProductType,
      contractTemplateId: contractTemplateId!,
      duration,
      value: !value && valueType === 'Mileage' ? mileage : value,
      valueType: valueType,
      startValueType: startValueType,
      mileage,
      startDateISOString: '',
      registrationDate: regDate
        ? formatDate(regDate, { rawFormat: 'yyyy-MM-dd' })
        : formatDate(new Date(), { rawFormat: 'yyyy-MM-dd' }),
      optionIds,
      providerPayments,
      providerShare: providerShare.priceInclVat,
      isAdjustment: adjustedFrom ? true : false,
      adjustedFrom,
      isDownpaymentDistributed: flags[0] === 'DISTRIBUTE_DOWNPAYMENT',
      modelYear: vehicleRecord.modelYear,
      registrationNumber: 'vin' in vehicleRecord ? vehicleRecord.regNumber || '' : '',
      serviceVariantId: contractSpecifics.serviceVariantId,
      startMileage: contractSpecifics.startMileage,
    }

    let request:
      | IStandardContractCalculationRequest
      | IStandardAxContractCalculationRequest
      | ICustomContractCalculationRequest
      | undefined

    if (contractType === 'STANDARD' && brand.id && model.id && fuelType && fuelType.id) {
      request = {
        ...baseOffer,
        brandId: brand.id,
        productModelId: model.id,
        fuelTypeId: fuelType.id,
      }
    } else if (contractType === 'STANDARD' && brand.name && model.name && fuelType && fuelType.name) {
      request = {
        ...baseOffer,
        brandName: brand.name,
        productModelName: model.name,
        fuelTypeName: fuelType.name,
      }
      request = undefined
    } else if (contractType === 'CUSTOM') {
      request = {
        ...baseOffer,
        brandName: vehicleRecord.brand.name || '',
        productModelName: vehicleRecord.model.name || '',
        fuelTypeName: (vehicleRecord.fuelType && vehicleRecord.fuelType.name) || '',
        amountPerPayment: monthlyTemplatePrice.priceInclVat,
      }
    }

    if (request) {
      const response = await calculateContractOffer(request)

      if (response.statusCode === 200 && response.data) {
        this.setState({ contractCalculationResponse: response.data })
      } else {
        // calculateContractOffer failed!
        notify.error({ message: response?.errorData?.message })

        // Send error log to Sentry (only in staging/production).
        const env = CONFIG.env
        const errorEventToSentry: SentryEvent = {
          message:
            'Received error from response from calculateContractOffer(request) in calculateDownpayment(..) in class ContractDetailsPage',
          extra: {
            statusCode: response?.statusCode,
            message: response?.errorData?.message,
          },
          level: Severity.Error,
          environment: env,
        }
        if (env === 'production' || env === 'staging') {
          captureEvent(errorEventToSentry)
        } else {
          console.error(errorEventToSentry)
        }
        if (env !== 'production') {
          console.error('response:')
          console.error(response)
        }

        throw Error('GENERIC_ERROR')
        //return
      }
    }
  }

  private getCreationData = async () => {
    const response = await getContractCreationData()

    if (response.data) {
      this.setState({ creationData: response.data })
    }
  }

  private initContractPayment = async () => {
    try {
      this.setState({ gettingContractPaymentData: true })
      const prettyIdentifier: string = this.props.match.params.prettyIdentifier
      await Promise.all([this.getCreationData(), this.calculateDownpayment()]).then(() => {
        const { creationData } = this.state
        if (creationData !== undefined) {
          browserHistory.push(stripePaymentPath(prettyIdentifier))
        }
      })
    } finally {
      this.setState({ gettingContractPaymentData: false })
    }
  }

  private handleInfoMessageShow = () => this.setState({ isSettleMessageShown: true })
  private handleInfoMessageHide = () => this.setState({ isSettleMessageShown: false })

  private onWorkshopListUpdate = () =>
    this.setState((state) => ({ workshopOperationsUpdateCounter: state.workshopOperationsUpdateCounter + 1 }))

  private handleTabForSettleButton = () => this.getActiveTab() !== 'SETTLE' && this.handleTabChange('SETTLE')

  private updateHiddenVAT = () => {
    this.props.setHiddenVAT(
      checkWhetherHideVAT(
        this.state.contractSpecifics.contractProductType === 'Warranty',
        undefined,
        new Date(this.state.contractSpecifics.startDate),
      ),
    )
  }
}

const mapDispatch = (dispatch: Dispatch<ActionTypes>) => ({
  setHiddenVAT: (hiddenVAT: boolean) => dispatch(setHiddenVAT(hiddenVAT)),
  setShowMileageInfo: (showMileageInfo: boolean) => dispatch(setShowMileageInfo(showMileageInfo)),
})

export default compose<TProps, {}>(withStyles(styles), connect(null, mapDispatch))(ContractDetailsPage)
