import { CssBaseline } from '@material-ui/core'
import { createTheme, MuiThemeProvider, withStyles, WithStyles } from '@material-ui/core/styles'
import { init as initFormats } from '@omnicar/sam-format'
import { setLocale } from '@omnicar/sam-translate'
import { IAdminCustomer, IAdminUserInfo, IsoLocale, TCurrency, TIsoCountry, UserRole } from '@fragus/sam-types'
import ActionTypes from 'actions/ActionTypes'
import { applicationUpdate, IApplicationUpdate } from 'actions/applicationActions'
import { updateCustomer, updateRole } from 'api/api'
import CookiesConsentBanner from 'components/CookiesConsentBanner'
import { WithTracker } from 'components/Tracker/Tracker'
//import { CONFIG } from 'config'
//import { detect } from 'detect-browser'
import OperationStatusPage from 'pages/admin/OperationStatusPage'
import Error404Page from 'pages/Error404Page/Error404Page'
import LogInPage from 'pages/LogInPage/LogInPage'
import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { IRootState } from 'reducers/initialState'
import {
  contractListPath,
  homePagePath,
  login404Path,
  loginAuthPath,
  loginForgotPath,
  loginPath,
  loginResetPath,
  loginUserPath,
  operationStatusPath,
  pageNotFoundPath,
  unAuthorizedPath,
} from 'routes/paths'
import { AppContext, appStore, IAppContext } from 'store/appContext'
import { theme as customTheme } from 'theme'
//import { t } from 'translations/translationFunctions'
import { clearTokens, setRefreshTokenInterval } from 'utils/auth'
import { getCookiesConsent, getRefreshToken, saveRefreshToken } from 'utils/cookies'
import { getAvailableLocales, getPreferredUserLocale, languageToLocale, LOCALE_CODE_DEFAULT } from 'utils/locale'
import {
  getAuth,
  getCurrency,
  getProviderCountry,
  isLoggedIn,
  isUseWhiteBGTheme,
  setAuth,
  setCustomerInfo,
  setToken,
  setUserInfo,
} from 'utils/localStorage'
//import notify from 'utils/notify/notify'
import { ensureTranslations } from 'utils/translation'
import Error401Page from '../../pages/Error401Page/Error401Page'
import Admin from './Admin'
import CustomerPortal from './CustomerPortal'
import styles from './styles'

interface IActionProps {
  applicationUpdate: (loggedIn: boolean) => IApplicationUpdate
}

interface IReducerProps {
  loggedIn: boolean
}

interface IProps extends WithStyles<typeof styles> {}

type TProps = RouteComponentProps<{}> & IReducerProps & IActionProps & IProps

interface IState {
  locale: IsoLocale | string
  availableLocales: IsoLocale[] | null
}

class App extends React.Component<TProps, IState> {
  public theme: any

  constructor(props: TProps) {
    super(props)

    this.state = {
      locale: LOCALE_CODE_DEFAULT,
      availableLocales: null,
    }

    this.initTheme()
    this.initFormats(LOCALE_CODE_DEFAULT as IsoLocale)
    this.initTranslations()
  }

  public componentDidMount() {
    // Check if browser is supported after reloading page.
    // Too noisy for users on smaller screens and unsupported browsers/devices. Removed for now 240531 Johan P
    //(!CONFIG.browser.disableBrowserCheck && this.checkIfBrowserIsSupported()

    if (getAuth()) {
      setRefreshTokenInterval()
    }

    this.props.applicationUpdate(isLoggedIn())
  }

  public render() {
    const { theme } = this
    const { classes, location, loggedIn: storeLoggedIn } = this.props
    const { locale } = this.state

    const showCookiesConsent = !getCookiesConsent()
    const samAdminAuth = getAuth()
    const loggedIn = storeLoggedIn || isLoggedIn()

    this.initFormats(locale as IsoLocale)

    const appContext: IAppContext = {
      ...appStore,
      role: samAdminAuth && samAdminAuth.role,
      roles: samAdminAuth && samAdminAuth.roles,
      stripePublicKey: samAdminAuth && samAdminAuth.stripePublicKey,
      providerInfo: samAdminAuth && samAdminAuth.providerInfo,
      userInfo: samAdminAuth && samAdminAuth.userInfo,
      updateUserRole: this.updateUserRole,
      locale: locale as IsoLocale, // Note: Needed explicitly deep down in a couple of places (f.ex. so months can be rendered in current locale and not formatted/rendered in what country the provider and fomatting rules are set).
      isSuperAdmin: !!(samAdminAuth && samAdminAuth.isSuperAdmin),
      isDev: !!(samAdminAuth && samAdminAuth.isDev),
      changeLocaleHandler: (locale: IsoLocale | string) => this.setGlobalLocale(locale), // Note: Needed by LanguageSelector!
      customerInfo: samAdminAuth && samAdminAuth.customerInfo,
      updateCustomerInfo: this.updateCustomerInfo,
    }

    if (loggedIn && (location.pathname.indexOf(loginResetPath) !== -1 || location.pathname.indexOf(loginPath) !== -1)) {
      clearTokens()
    }

    // TODO: Move roles to redux, subscribe individual components to role changes
    // instead of unnecessarily re-rendering most of the page.
    const routerKey = `${appContext.role} ${appContext.providerInfo && appContext.providerInfo.providerId}`

    return (
      <AppContext.Provider value={appContext}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <div
            className={`App ${classes.root} ${
              samAdminAuth && samAdminAuth.role === 'customer' ? 'App-customer' : 'App-admin'
            }`}
          >
            <Switch key={routerKey}>
              <Route exact={true} path={pageNotFoundPath} render={WithTracker(Error404Page)} />
              <Route exact={true} path={unAuthorizedPath} render={WithTracker(Error401Page)} />
              {loggedIn ? (
                <Switch>
                  <Route exact={true} path={loginPath} render={WithTracker(LogInPage)} />
                  <Route exact={true} path={loginResetPath} render={WithTracker(LogInPage)} />
                  <Route path="*" render={this.renderPortal(appContext.role)} />
                  {appContext.isDev && (
                    <Route exact={true} path={operationStatusPath} render={WithTracker(OperationStatusPage)} />
                  )}
                </Switch>
              ) : (
                <Switch>
                  {/* We need to define all routes for login to make them accessible even without transitions */}
                  <Route exact={true} path={homePagePath} render={WithTracker(LogInPage)} />
                  <Route exact={true} path={loginPath} render={WithTracker(LogInPage)} />
                  <Route exact={true} path={loginUserPath()} render={WithTracker(LogInPage)} />
                  <Route exact={true} path={loginAuthPath()} render={WithTracker(LogInPage)} />
                  <Route exact={true} path={loginForgotPath()} render={WithTracker(LogInPage)} />
                  <Route exact={true} path={loginResetPath} render={WithTracker(LogInPage)} />
                  <Route exact={true} path={login404Path()} render={WithTracker(LogInPage)} />
                  <Route
                    path={'*'}
                    // tslint:disable-next-line:jsx-no-lambda
                    render={() => <Redirect to={homePagePath} />}
                  />
                </Switch>
              )}
            </Switch>
          </div>
          {showCookiesConsent && <CookiesConsentBanner />}
        </MuiThemeProvider>
      </AppContext.Provider>
    )
  }

  private renderPortal = (role: UserRole | undefined) => () =>
    role === 'customer' ? (
      <CustomerPortal initTranslations={this.initTranslations} />
    ) : (
      <Admin initTranslations={this.initTranslations} />
    )

  private mapCustomerToUser = ({ name, phone, address, city, zip, email }: IAdminCustomer): IAdminUserInfo => ({
    name,
    phone,
    address,
    city,
    email,
    zip: zip.toString(),
  })

  // Too noisy for users on smaller screens and unsupported browsers/devices. Removed for now 240531 Johan P
  // private checkIfBrowserIsSupported() {
  //   const browser = detect()
  //   if (browser && !this.browserIsSupported(browser.name.toLocaleLowerCase())) {
  //     notify.warning({
  //       message: t(
  //         `This browser is not supported by JustGO, some features might not function properly. We strongly recommend using one of the following browsers on a personal computer for the best experience: %browserList`,
  //         { browserList: this.getItemsAsCommaSeperatedText(CONFIG.browser.supportedBrowsers) },
  //       ),
  //       time: 40,
  //     })
  //   }
  // }

  // Too noisy for users on smaller screens and unsupported browsers/devices. Removed for now 240531 Johan P
  // private browserIsSupported(browser: string) {
  //   return !!CONFIG.browser.supportedBrowsers.filter((sb) => browser.startsWith(sb)).length
  // }

  // Too noisy for users on smaller screens and unsupported browsers/devices. Removed for now 240531 Johan P
  // private getItemsAsCommaSeperatedText(array: string[]) {
  //   return array.reduce(
  //     (text, item, i) =>
  //       (text += `${item}${array.length > 1 && i === array.length - 2 ? ' or ' : i !== array.length - 1 ? ', ' : ''}`),
  //     '',
  //   )
  // }

  private updateCustomerInfo = async (customerInfo: IAdminCustomer): Promise<void> => {
    const res = await updateCustomer(customerInfo, customerInfo.prettyIdentifier!)
    if (!res.data) {
      return Promise.reject()
    }

    setUserInfo(this.mapCustomerToUser(res.data))
    setCustomerInfo(res.data)
    this.forceUpdate()
  }

  private updateUserRole = async (roleId: number, contractProviderId: number) => {
    const body = { roleId, contractProviderId }
    const refreshToken = getRefreshToken()
    const res = await updateRole({ ...body, token: refreshToken || '' })

    if (!res.data) {
      throw new Error()
    }
    const { token, role, userInfo, providerInfo, refreshToken: newRefreshToken } = res.data
    const oldAuth = getAuth()!

    setToken(token)
    setAuth({
      ...oldAuth,
      role,
      userInfo,
      ...(providerInfo && { providerInfo }),
    })
    newRefreshToken && saveRefreshToken(newRefreshToken)
    // This notify is a bit disturbing... TODO: Find a less disturbing way to communicate success to the user
    // notify.success({ message: t('Role changed') })

    // Switching providers.
    if (!oldAuth.providerInfo || !providerInfo || oldAuth.providerInfo.providerId !== providerInfo.providerId) {
      this.props.history.replace(contractListPath)

      window.location.reload() // (!) Important: Triggers a reload of the current page in the browser, including updating the theme/styling and logo (even after updating a new logo on a provider) of the new provider.
    } else {
      // User's role within a single provider has changed. Router needs a little nudge.
      this.forceUpdate()
    }
  }

  private initTheme = () => {
    // NOTE: Switching only the palette is a work-around until/meanwhile theme
    // switching is not set up.
    const theme = createTheme({
      palette: !isUseWhiteBGTheme()
        ? { primary: customTheme.palette.primary, secondary: customTheme.palette.secondary }
        : { primary: customTheme.paletteWhiteBG.primary, secondary: customTheme.paletteWhiteBG.secondary },
      // palette: { primary: customTheme.palette.primary, secondary: customTheme.palette.secondary },
      // palette: { primary: customTheme.paletteWhiteBG.primary, secondary: customTheme.paletteWhiteBG.secondary },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1180,
          xl: 1920,
        },
      },
    })

    // @TODO - Put this in a context provider App wide?
    this.theme = theme
  }

  private initFormats = async (locale: IsoLocale) => {
    const country = getProviderCountry()
    const currency = getCurrency()

    const options = {
      countryCode: (country || 'International') as TIsoCountry,
      currencyCode: (currency || 'EUR') as TCurrency,
      language: (locale || 'en') as IsoLocale, // May be used in some rare cases, like in dates.
    }
    initFormats(options)
  }

  private initTranslations = async (): Promise<void> => {
    const samAdminAuth = getAuth()
    const userEmail: any = samAdminAuth && samAdminAuth.userInfo && samAdminAuth.userInfo.email

    if (!userEmail) {
      await ensureTranslations(this.state.locale as IsoLocale)
    } else {
      const response = await getAvailableLocales()
      const availableLocales = !response ? [LOCALE_CODE_DEFAULT] : response
      const localeOrLanguage = await getPreferredUserLocale(userEmail, availableLocales as string[])

      this.setState({ availableLocales })
      this.setGlobalLocale(localeOrLanguage)
    }
  }

  private async setGlobalLocale(localeOrLanguage: IsoLocale | string) {
    const availableLocales = this.state.availableLocales

    if (localeOrLanguage && availableLocales) {
      let locale
      if (localeOrLanguage.length === 2) {
        locale = languageToLocale(localeOrLanguage, availableLocales)
      } else {
        locale = localeOrLanguage
      }

      if (locale) {
        await ensureTranslations(locale as IsoLocale)
        if (localeOrLanguage.length === 2) {
          setLocale(locale)
          this.setState({ locale: locale as IsoLocale }) // Will re-render.
        }
      }
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  loggedIn: state.application.loggedIn,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  applicationUpdate: (loggedIn: boolean) => dispatch(applicationUpdate({ loggedIn })),
})

export default compose<TProps, {}>(withRouter, withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(App)
