import { withStyles, WithStyles } from '@material-ui/core'
import {
  IApiOtherContract,
  IApiOtherWithContracts,
  IApiVehicleContract,
  IApiVehicleWithContracts,
  IProviderWithContracts,
  TContractObject,
} from '@fragus/sam-types'
import { getCustomerProvidersAndProducts, getCustomerProvidersAndVehicles } from 'api/api'
import classNames from 'classnames'
import AccountSettings from 'components/customer/AccountSettings'
import CustomerContractDetails from 'components/customer/Contract/Details'
import { PageNavigation } from 'components/Mui/Page'
import AppHeader from 'containers/App/CustomerPortal/AppHeader'
import AppSidebar from 'containers/App/CustomerPortal/AppSidebar'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { IRootState } from 'reducers/initialState'
import { customerContractDetailsPath } from 'routes/paths'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import { trackEvent } from 'utils/analytics'
import { getProviderIdByContractIdentifier } from 'utils/contract'
import browserHistory from 'utils/history'
import { getParameterByName } from 'utils/path'
import styles from './styles'

interface IRouteProps {
  prettyIdentifier: string
}

interface IProps extends WithStyles<typeof styles> {}

interface IOutageProps {
  isActiveOutage: boolean
}

type TProps = IProps & RouteComponentProps<IRouteProps> & IOutageProps

type IApiGenericWithContracts = IApiVehicleWithContracts | IApiOtherWithContracts

export interface ITab<T = string> {
  title: string
  name: T
  e2e?: string
}

interface IState {
  vehicles: IApiVehicleWithContracts[]
  providers: IProviderWithContracts[]
  providersWithProducts: IProviderWithContracts[]
  products: IApiOtherWithContracts[]
  hideTabs: boolean
  selectedContract: IApiVehicleContract | undefined
  selectedVehicle: IApiGenericWithContracts | undefined
  isVehicleListOpen: boolean
  isAccountSettingsOpen: boolean
  activeTab: TContractObject
}

class ContractDetailsPage extends React.Component<TProps, IState> {
  public state: IState = {
    vehicles: [],
    providers: [],
    providersWithProducts: [],
    products: [],
    hideTabs: true,
    selectedContract: undefined,
    selectedVehicle: undefined,
    isVehicleListOpen: false,
    isAccountSettingsOpen: false,
    activeTab: 'Vehicle' as const,
  }

  public async componentDidMount() {
    const { prettyIdentifier } = this.props.match.params
    const response = await getCustomerProvidersAndVehicles()
    const productResponse = await getCustomerProvidersAndProducts()
    let selectedVehicle: IApiVehicleWithContracts | IApiOtherWithContracts | undefined
    let selectedContract: IApiVehicleContract | IApiOtherContract | undefined
    let hideTabs = false
    let activeTab: TContractObject = 'Vehicle'

    if (response.data) {
      const vehicles = response.data.result[0].vehicles as IApiVehicleWithContracts[]
      // if exactly 1 vehicle with exactly 1 contract: auto-select the contract
      if (vehicles.length === 1 && vehicles[0].contracts.length === 1 && !prettyIdentifier) {
        selectedVehicle = vehicles[0]
        selectedContract = vehicles[0].contracts[0]
        browserHistory.push(customerContractDetailsPath(selectedContract.prettyIdentifier))
      } else {
        selectedVehicle = prettyIdentifier ? this.getVehicleByContractPrId(prettyIdentifier, vehicles) : undefined
        selectedContract = selectedVehicle
          ? selectedVehicle.contracts.find((c) => c.prettyIdentifier === prettyIdentifier)
          : undefined
      }

      // open sidebar (on mobile) if multiple vehicles
      // but keep sidebar closed (on mobile) after adding new card
      const isNewCardAdded = getParameterByName('isNewAdded', window.location.href)
      const isVehicleListOpen = vehicles.length > 1 && !selectedVehicle && !isNewCardAdded

      hideTabs = !vehicles || !vehicles.length

      this.setState({ providers: response.data.result, vehicles, isVehicleListOpen })
    }

    if (productResponse.data && productResponse.data.result.length) {
      const products = productResponse.data.result[0].products
      // only auto-select a product contract if customer has no vehicle contracts
      if (products && !selectedVehicle && !selectedContract) {
        if (selectedContract && products.length === 1 && products[0].contracts.length === 1 && !prettyIdentifier) {
          selectedVehicle = products[0]
          selectedContract = products[0].contracts[0]
          selectedContract && browserHistory.push(customerContractDetailsPath(selectedContract.prettyIdentifier))
        } else {
          selectedVehicle = prettyIdentifier ? this.getProductByContractPrId(prettyIdentifier, products) : undefined
          selectedContract = selectedVehicle
            ? selectedVehicle.contracts.find((c) => c.prettyIdentifier === prettyIdentifier)
            : undefined
        }
      }

      hideTabs = hideTabs || !products || !products.length
      activeTab = hideTabs && products && products.length ? 'Product' : 'Vehicle'
      this.setState({ providersWithProducts: productResponse.data.result, products: products || [] })
    } else {
      hideTabs = true
    }

    this.setState({ activeTab, hideTabs, selectedContract, selectedVehicle })
  }

  public render() {
    const { classes, isActiveOutage } = this.props
    const {
      providers,
      providersWithProducts,
      // vehicles,
      // products,
      hideTabs,
      activeTab,
      selectedContract,
      selectedVehicle,
      isVehicleListOpen,
      isAccountSettingsOpen,
    } = this.state

    const tabSetup = {
      hideTabs: hideTabs,
      tabs: this.getHeaderTabs(),
      activeTab,
      tabClasses: { container: '', tab: classes.tab, activeTab: classes.activeTab },
      handleTabChange: this.handleTabChange,
    }
    const contractProvider = this.getContractProviderBySelectedContract()

    return (
      <section className={classNames('ContractDetailsPage', classes.cpBackground)}>
        <header className={classes.header}>
          <AppHeader
            onToggleSidebar={this.toggleVehicleList}
            isSidebarOpen={isVehicleListOpen}
            onToggleAccountSettings={this.toggleAccountSettings}
          />
        </header>
        <div className={classes.content}>
          <aside className={classNames(classes.aside, isActiveOutage ? classes.minimumPaddingTopAside : '')}>
            <div className={classes.flexContainer}>
              {!hideTabs && (
                <div>
                  <PageNavigation
                    active={activeTab}
                    tabClassName={classes.tab}
                    activeTabClassName={classes.activeTab}
                    onChange={this.handleTabChange}
                    tabs={this.getHeaderTabs()}
                  />
                </div>
              )}
              <AppSidebar
                open={isVehicleListOpen}
                onCloseSidebar={this.toggleVehicleList}
                providers={activeTab === 'Vehicle' ? providers : providersWithProducts}
                // vehicles={activeTab === 'Vehicle' ? vehicles : products}
                onContractSelect={this.handleContractSelect}
                // TODO: solve this typing issue!
                selectedContract={selectedContract as never}
                tabSetup={tabSetup}
              />
            </div>
          </aside>
          <main
            className={classNames(classes.main, isActiveOutage ? classes.minimumPaddingTopMain : '')}
            data-e2e={'ContractDetailsPage__main'}
          >
            <CustomerContractDetails
              contract={selectedContract}
              vehicle={selectedVehicle}
              providerCountry={contractProvider?.provider.country}
            />
          </main>

          <AppContext.Consumer>
            {({ customerInfo, updateCustomerInfo }) => (
              <AccountSettings
                open={isAccountSettingsOpen}
                onClose={this.toggleAccountSettings}
                customerInfo={customerInfo}
                updateCustomerInfo={updateCustomerInfo}
              />
            )}
          </AppContext.Consumer>
        </div>
      </section>
    )
  }

  private getContractProviderBySelectedContract = (): IProviderWithContracts | undefined => {
    const { selectedContract, providers } = this.state

    return (
      selectedContract &&
      providers.find(
        (p) => p.provider.providerId === getProviderIdByContractIdentifier(selectedContract?.prettyIdentifier),
      )
    )
  }

  private getHeaderTabs = (): Array<ITab<TContractObject>> => {
    return [
      { title: t('Vehicle'), name: 'Vehicle' },
      { title: t('Product'), name: 'Product' },
    ]
  }

  private handleTabChange = (value: any) => {
    this.setState({ activeTab: value })
  }

  private getVehicleByContractPrId = (prettyIdentifier: string, vehicles: IApiVehicleWithContracts[]) =>
    vehicles.find((v) => v.contracts.some((c) => c.prettyIdentifier === prettyIdentifier))

  private getProductByContractPrId = (prettyIdentifier: string, products: IApiOtherWithContracts[]) =>
    products.find((p) => p.contracts.some((c) => c.prettyIdentifier === prettyIdentifier))

  private handleContractSelect = (
    selectedContract: IApiVehicleContract | undefined,
    selectedVehicle: IApiVehicleWithContracts | IApiOtherWithContracts | undefined,
  ) => {
    trackEvent('Customer portal', 'Select contract')
    this.setState({ selectedContract, selectedVehicle, isVehicleListOpen: false })
    selectedContract && browserHistory.push(customerContractDetailsPath(selectedContract.prettyIdentifier))
  }

  private toggleVehicleList = () => {
    this.setState({ isVehicleListOpen: !this.state.isVehicleListOpen })
  }

  private toggleAccountSettings = (): void =>
    this.setState({ isAccountSettingsOpen: !this.state.isAccountSettingsOpen })
}

const mapStateToProps = (state: IRootState) => ({
  isActiveOutage: !!state.outage,
})

export default withStyles(styles)(connect(mapStateToProps)(ContractDetailsPage))
