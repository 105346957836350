import React, { Component } from 'react'
import { withStyles, WithStyles, CardContent, CardActions, Button } from '@material-ui/core'
import { t } from 'translations/translationFunctions'
import { ICreditCardRecord } from '@fragus/sam-types'
import styles from './styles'
import Typography from 'components/Typography'
import { Card } from 'components/Mui/Card'

interface IProps extends WithStyles<typeof styles> {
  className?: string
  card: ICreditCardRecord
  onDeleteCard: (prettyId: string) => void
  onMakeCardDefault: (prettyId: string) => void
  changesAllowed: boolean
}

interface IState {}

class CreditCard extends Component<IProps, IState> {
  public state = {}

  public render() {
    const { className, classes, card } = this.props
    const expireDate = card ? `${card.expireMonth}/${card.expireYear}` : null

    return (
      <React.Fragment>
        {!card ? null : (
          <Card component="li" className={`${className ? className : ''} ${classes.root}`}>
            <CardContent className={classes.content}>
              <img className={classes.creditCardIcon} src={this.getCardIconSrc(card.brand, card.isDefault)} alt="" />
              <div className={classes.cardInfoWrap}>
                <Typography variant="title" className={classes.cardInfo}>
                  <span>{card.brand}</span>
                  <span>{`**** ${card.last4}`}</span>
                </Typography>
                <Typography variant="body1" className={classes.cardExpires}>
                  <span>{`${t('Expires')} ${expireDate}`}</span>
                </Typography>
              </div>
            </CardContent>
            {this.renderCardActions(card.isDefault)}
          </Card>
        )}
      </React.Fragment>
    )
  }

  private renderCardActions = (isDefault: boolean) => {
    const { classes, onDeleteCard, onMakeCardDefault, card, changesAllowed } = this.props

    return isDefault ? (
      <div className={classes.bottom}>
        <Typography className={classes.labelActive}>{t('Active')}</Typography>
      </div>
    ) : (
      <CardActions className={`${classes.bottom} ${classes.bottomInactive}`}>
        <Button
          size="small"
          className={classes.btnDelete}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => onDeleteCard(card.cardId)}
          disabled={!changesAllowed}
        >
          {t('Delete')}
        </Button>

        <Button
          size="small"
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => onMakeCardDefault(card.cardId)}
          disabled={!changesAllowed}
        >
          {t('Make default')}
        </Button>
      </CardActions>
    )
  }

  private getCardIconSrc = (cardBrand: string, isDefault: boolean) => {
    const brand = cardBrand.toLowerCase()

    if (brand.includes('visa')) {
      if (isDefault) {
        return require('payment-icons/min/flat/visa.svg')
      }
      return require('payment-icons/min/mono/visa.svg')
    } else if (brand.includes('mastercard')) {
      if (isDefault) {
        return require('payment-icons/min/flat/mastercard.svg')
      }
      return require('payment-icons/min/mono/mastercard.svg')
    } else if (brand.includes('american express')) {
      if (isDefault) {
        return require('payment-icons/min/flat/amex.svg')
      }
      return require('payment-icons/min/mono/amex.svg')
    } else {
      return require('payment-icons/min/flat/visa.svg')
    }
  }
}

export default withStyles(styles)(CreditCard)
