import { Button, Drawer, WithStyles, withStyles } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import {
  ArrowForward as ArrowForwardIcon,
  KeyboardArrowRight as ArrowForwardIosIcon,
  Close as CloseIcon,
} from '@material-ui/icons'
import { IAdminCustomer, IUserPasswordChangeRequest } from '@fragus/sam-types'
import { updatePassword } from 'api/api'
import LoadingOverlay from 'components/LoadingOverlay'
import Typography from 'components/Typography'
import React, { Component, ComponentType } from 'react'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import notify from 'utils/notify/notify'
import ConfirmDialog from '../../ConfirmDialog'
import AccountSettingsPasswordForm from './Forms/Password'
import AccountSettingsUserForm from './Forms/User'
import AccountSettingsHeader from './Header'
import styles from './styles'

enum FormVisible {
  User,
  Password,
}

interface IProps extends WithStyles<typeof styles> {
  open: boolean
  onClose: () => void
  customerInfo: IAdminCustomer | undefined
  updateCustomerInfo: (c: IAdminCustomer) => Promise<IAdminCustomer>
}

interface IState {
  heading: TranslationKey
  handleCloseButtonClick: () => void
  headerIcon: ComponentType<SvgIconProps>
  formVisible: FormVisible
  isFormDirty: boolean
  showDiscardDialog: boolean
  cancelCount: number
  isSubmitting: boolean
}

class AccountSettings extends Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = {
      formVisible: FormVisible.User,
      heading: 'Account Settings',
      headerIcon: ArrowForwardIcon,
      handleCloseButtonClick: this.handleCloseAccountSettings,
      isFormDirty: false,
      showDiscardDialog: false,
      cancelCount: 0,
      isSubmitting: false,
    }
  }

  public render() {
    const { customerInfo: customer, classes, open } = this.props
    const {
      heading,
      headerIcon,
      formVisible,
      showDiscardDialog,
      handleCloseButtonClick,
      cancelCount,
      isSubmitting,
    } = this.state

    return (
      <>
        {customer && (
          <Drawer
            classes={{
              paper: classes.root,
            }}
            anchor="right"
            open={open}
            onClose={handleCloseButtonClick}
          >
            <AccountSettingsHeader
              handleCloseButtonClick={handleCloseButtonClick}
              heading={t(heading)}
              icon={headerIcon}
            />
            {formVisible === FormVisible.User ? (
              <>
                <AccountSettingsUserForm
                  customer={customer}
                  onFormDirty={this.handleFormDirty}
                  onSubmit={this.handleAccountFormSubmit}
                  onCancel={this.handleAccountFormCancel}
                  cancelCount={cancelCount}
                />

                <hr className={classes.hr} />
                <Button className={classes.actionBar} onClick={this.setPasswordFormVisible}>
                  <Typography className={classes.changePassword} variant="subheading">
                    {t('Change password')}
                  </Typography>
                  <ArrowForwardIosIcon className={classes.changePassword} />
                </Button>
              </>
            ) : (
              <AccountSettingsPasswordForm onSubmit={this.handlePasswordFormSubmit} cancelCount={cancelCount} />
            )}
          </Drawer>
        )}

        <ConfirmDialog
          titleTrl={'Discard unsaved changes?'}
          open={showDiscardDialog}
          onConfirm={this.handleDiscardDialogConfirm}
          onCancel={this.handleDiscardDialogCancel}
        />

        <LoadingOverlay open={isSubmitting} />
      </>
    )
  }

  private handleFormDirty = (isFormDirty: boolean) => {
    this.setState({ isFormDirty })
  }

  private setPasswordFormVisible = () =>
    this.setState({
      formVisible: FormVisible.Password,
      heading: 'Change password',
      headerIcon: CloseIcon,
      handleCloseButtonClick: this.setUserFormVisible,
    })

  private setUserFormVisible = () =>
    this.setState({
      formVisible: FormVisible.User,
      heading: 'Account Settings',
      headerIcon: ArrowForwardIcon,
      handleCloseButtonClick: this.handleCloseAccountSettings,
    })

  private handleCloseAccountSettings = () => {
    if (this.state.isFormDirty) {
      this.setState({ showDiscardDialog: true })
    } else {
      this.setState({ showDiscardDialog: false }, () => {
        // show user form next time Account Settings is open (default)
        this.setUserFormVisible()
        // close Account Settings
        this.props.onClose()
      })
    }
  }

  private handleAccountFormSubmit = async (customer: IAdminCustomer) => {
    this.setState({ isSubmitting: true })

    await this.props.updateCustomerInfo(customer).then(() => {
      this.setState({ isFormDirty: false, showDiscardDialog: false })
      this.handleCloseAccountSettings()
      notify.success({ message: t('User updated') })
    })

    this.setState({ isSubmitting: false })
  }

  private handleAccountFormCancel = () => {
    this.setState({ isFormDirty: false, cancelCount: this.state.cancelCount + 1 })
  }

  private handleDiscardDialogCancel = () => {
    this.setState({ showDiscardDialog: false })
  }

  private handleDiscardDialogConfirm = () => {
    this.setState({ isFormDirty: false, showDiscardDialog: false, cancelCount: this.state.cancelCount + 1 }, () => {
      this.handleCloseAccountSettings()
    })
  }

  private handlePasswordFormSubmit = async (body: IUserPasswordChangeRequest) => {
    // show spinner
    this.setState({ isSubmitting: true })

    // API request
    const res = await updatePassword(body)

    // success
    if (res.data) {
      // close account settings
      this.handleCloseAccountSettings()
      // notify user
      notify.success({ message: t('Password changed') })
    }

    // remove spinner
    this.setState({ isSubmitting: false })
  }
}

export default withStyles(styles)(AccountSettings)
