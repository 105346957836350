import { TranslationKey } from '../translations/translationTypes'
import { IApiOtherWithContracts, IApiVehicleContract, IApiVehicleWithContracts } from '@fragus/sam-types'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { CarIcon } from './vehicle'
import { ProductIcon } from './product'

export interface IGenericType {
  identifier1: string
  identifierTitle1: TranslationKey
  identifier2: string
  identifierTitle2: TranslationKey
  brandName: string
  modelName: string
  fuelTypeName: string
  registrationDate: Date
}

export interface IGenericTypeWithContracts extends IGenericType {
  contracts: IApiVehicleContract[]
}

export function mapVehiclesToGenerics(vehicles: IApiVehicleWithContracts[] | IApiOtherWithContracts[]) {
  let generics: IGenericTypeWithContracts[] = []
  for (let v of vehicles) {
    const mappedVehicle = mapVehicleToGeneric(v)
    if (mappedVehicle && mappedVehicle.vehicle) generics.push(mappedVehicle.vehicle)
  }

  return generics
}

export function mapVehicleToGeneric(
  vehicle: IApiVehicleWithContracts | IApiOtherWithContracts,
): { vehicle: IGenericTypeWithContracts; icon: React.ComponentType<SvgIconProps> } {
  if ('vin' in vehicle) {
    return {
      vehicle: {
        identifierTitle1: 'Registration Number',
        identifier1: vehicle.registrationNumber,
        identifierTitle2: 'VIN',
        identifier2: vehicle.vin,
        brandName: vehicle.brandName,
        modelName: vehicle.modelName,
        fuelTypeName: vehicle.fuelTypeName,
        registrationDate: new Date(vehicle.registrationDate),
        contracts: vehicle.contracts,
      },
      icon: CarIcon,
    }
  } else {
    const product = vehicle as IApiOtherWithContracts

    return {
      vehicle: {
        identifierTitle1: 'Item Number',
        identifier1: `${product.itemNumber} ${product.itemNumber2 ? `/ ${product.itemNumber2}` : ''}`,
        identifierTitle2: 'Serial Number',
        identifier2: `${product.serialNumber} ${product.serialNumber2 ? `/ ${product.serialNumber2}` : ''}`,
        brandName: product.brandName,
        modelName: product.modelName,
        fuelTypeName: product.fuelTypeName,
        registrationDate: new Date(product.registrationDate),
        contracts: vehicle.contracts,
      },
      icon: ProductIcon,
    }
  }
}
