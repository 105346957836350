import { AbstractStripeCheckoutPage, styles } from '../AbstractStripeCheckoutPage'
import { sendAddCardRequest } from '../../../api/api'
import { addCardErrorPath, addCardReceiptPath } from '../../../routes/paths'
import { IJsonStatus } from '@omnicar/sam-tfetch'
import { IPreparePaymentRequest, ApiError, IPreparePaymentResponse } from '@fragus/sam-types'
import { withStyles } from '@material-ui/core/styles'

class StripeAddCardPage extends AbstractStripeCheckoutPage {
  protected receiptPath = (prettyIdentifier: string) => {
    return addCardReceiptPath(prettyIdentifier)
  }

  protected errorPath = (prettyIdentifier: string) => {
    return addCardErrorPath(prettyIdentifier)
  }

  protected sendPrepareCheckoutRequest(
    body: IPreparePaymentRequest,
  ): Promise<IJsonStatus<IPreparePaymentResponse, ApiError>> {
    return sendAddCardRequest(body)
  }

  protected skipTermsCheck(): boolean {
    return true
  }
}

export default withStyles(styles)(StripeAddCardPage)
