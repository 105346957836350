import React from 'react'
import { withStyles, WithStyles, withWidth } from '@material-ui/core'
import { IApiVehicleContract, IInvoice } from '@fragus/sam-types'
import CustomerContractDetailsInvoices from 'components/customer/Contract/Details/Invoices'
import styles from './styles'
import { compose } from 'recompose'

interface IOwnProps {
  contract: IApiVehicleContract | undefined
  hidden: boolean
  disabled: boolean
  onInvoicesSave: (i: IInvoice[]) => void
  onSuccessfulRetry: () => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

const CustomerContractDetailsTabsInvoices: React.SFC<TProps> = ({
  classes,
  contract,
  hidden,
  disabled,
  onInvoicesSave,
  onSuccessfulRetry,
}) => {
  const allowRetryPayment = contract && contract.contractState !== 900
  return (
    <div hidden={hidden}>
      {contract && (
        <CustomerContractDetailsInvoices
          contract={contract}
          hidden={hidden}
          disabled={disabled}
          allowRetryPayment={allowRetryPayment}
          onInvoicesSave={onInvoicesSave}
          onSuccessfulRetry={onSuccessfulRetry}
        />
      )}
    </div>
  )
}

export default compose<TProps, IOwnProps>(withStyles(styles), withWidth())(CustomerContractDetailsTabsInvoices)
