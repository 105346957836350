import React from 'react'
import { WithStyles, withStyles } from '@material-ui/core'
import styles from './styles'
import { t } from 'translations/translationFunctions'
import Typography from 'components/Typography'
import { IApiVehicleContract, ContractState } from '@fragus/sam-types'
import classNames from 'classnames'

interface IOwnProps {
  contract: IApiVehicleContract
  isRetrySuccessful: boolean
  failedPaymentReason?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const InfoBar: React.SFC<TProps> = ({ classes, contract, isRetrySuccessful, failedPaymentReason }) => {
  const isSuspended = contract.contractState === ContractState.Suspended
  return (
    <>
      {/* Contract has been suspended */}
      {isSuspended &&
        (!isRetrySuccessful ? (
          <Typography
            className={classNames(classes.root, classes.warning)}
            variant="subtitle"
            data-e2e={'ContractDetails__InfoBar--payment-failed'}
          >
            {failedPaymentReason ||
              t(
                'The contract is suspended due to payment failure. Your credit card has expired or has insufficient funds.',
              )}
          </Typography>
        ) : (
          // Retry payment was successful, contract soon to be re-activated
          <Typography
            className={classNames(classes.root, classes.attention)}
            variant="subtitle"
            data-e2e={'ContractDetails__InfoBar--successful-retry'}
          >
            {t(
              'Thank you for initiating an attempt to collect the missing payments, this might take a few minutes, you will receive an email when your contract is re-activated.',
            )}
          </Typography>
        ))}
      {
        // contract is extended from another and in 'offer' state
        !!contract.extendedFrom && contract.contractState === ContractState.Offer && (
          <Typography
            className={classNames(classes.root, classes.attention)}
            variant="subtitle"
            data-e2e={'ContractDetails__InfoBar--extended-from'}
          >
            {t('This offer will change the current active contract of this vehicle')}
          </Typography>
        )
      }
    </>
  )
}

export default withStyles(styles)(InfoBar)
