import React from 'react'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import Typography from 'components/Typography'
import styles from './styles'
import { LocalPhone as PhoneIcon, Email as EmailIcon } from '@material-ui/icons'
import { formatPhone } from '@omnicar/sam-format'
import { Card } from 'components/Mui/Card'
import { IContractProviderInfo } from '@fragus/sam-types/types/admin/contractProvider'
import classNames from 'classnames'

interface IProps extends WithStyles<typeof styles> {
  provider: IContractProviderInfo
}

const ProviderCard: React.SFC<IProps> = ({ classes, provider, children }) => (
  <Card className={classes.providerCard} key={`${provider.providerId}`}>
    <div className={classes.providerInfoContainer}>
      <Typography className={classes.providerName} variant="title">
        {provider.administrativeName}
      </Typography>
      <a href={`tel:${provider.phone}`} className={classNames(classes.providerInfo, classes.linkButton)}>
        <PhoneIcon className={classes.linkIcon} />
        <Typography className={classes.providerInfo} variant="subtitle">
          {formatPhone(provider.phone)}
        </Typography>
      </a>
      <a href={`mailto:${provider.email}`} className={classNames(classes.providerInfo, classes.linkButton)}>
        <EmailIcon className={classes.linkIcon} />
        <Typography className={classes.providerInfo} variant="subtitle">
          {provider.email}
        </Typography>
      </a>
    </div>
    {!!children && children}
  </Card>
)

export default withStyles(styles)(ProviderCard)
