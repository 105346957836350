import { CardContent, InputLabel, TextField } from '@material-ui/core'
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { formatMileage } from '@omnicar/sam-format'
import { DurationOptions, IContractTemplateResponse } from '@fragus/sam-types'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import React, { ChangeEvent } from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'
import { ContractFlowActivePanel, IContractFlowMileageDuration } from 'types/contractFlow'
import { getClosestAllowedValue, getMileageList, getMileageValues } from 'utils/durationMileage'
import { zeroOrNumber } from 'utils/regex'
import ContractFlowDurationMileageSlider from './Slider'

export interface IDurationMileageValuesVer1 {
  duration: IContractFlowMileageDuration
  mileage: IContractFlowMileageDuration
}

interface IOwnProps {
  active: ContractFlowActivePanel
  activeEnum: ContractFlowActivePanel
  durations: DurationOptions[]
  freeContract: boolean
  template: IContractTemplateResponse | undefined
  value: IDurationMileageValuesVer1
  onChange: (values: IDurationMileageValuesVer1, valid: boolean) => void
  defaultMileageFreeContract?: number
  showCustomMileage: boolean
}

const defaultHighMilegaWarningLimit = 1000000

type TProps = WithStyles<typeof styles> & IOwnProps

interface IState {
  mileageValue?: number
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
    highMileageWarning: {
      color: customTheme.palette.context.attention[700],
    },
    highMileageWarningInput: {
      color: customTheme.palette.context.attention[700],
      '&:before': {
        borderBottomColor: customTheme.palette.context.attention[700],
      },
      '&:after': {
        borderBottomColor: customTheme.palette.context.attention[700],
      },
      '&:hover:before': {
        borderBottomColor: `${customTheme.palette.context.attention[700]} !important`,
      },
    },
  })

class ContractFlowDurationMileageVer1 extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)

    const { defaultMileageFreeContract, freeContract, showCustomMileage, value } = props
    const mileageValue = freeContract && showCustomMileage ? value.mileage.value : defaultMileageFreeContract

    this.state = {
      mileageValue,
    }
  }

  public componentDidUpdate(oldProps: TProps) {
    const oldMileageValue = oldProps.value.mileage.value
    const newMileageValue = this.props.value.mileage.value

    const oldDefaultMileage = oldProps.defaultMileageFreeContract
    const newDefaultMileage = this.props.defaultMileageFreeContract

    if (newMileageValue >= 0 && newMileageValue !== oldMileageValue) {
      this.setState({ mileageValue: newMileageValue })
    }

    if (newDefaultMileage && newDefaultMileage !== oldDefaultMileage) {
      this.setState({ mileageValue: newDefaultMileage })
    }
  }

  public render() {
    const { classes, freeContract, value } = this.props
    const { duration, mileage } = value
    const { handleMileageChange, handleDurationChange } = this
    const highMileageWarning = this.highMileageWarning()
    const isActive = this.isActive()

    const disableDurationSlider = !freeContract && duration.min === duration.max
    const disableMileageSlider = !freeContract && mileage.min === mileage.max

    return (
      <React.Fragment>
        <Panel disabled={!isActive}>
          <PanelHeader>
            <PanelTitle>{t('Duration')}</PanelTitle>
          </PanelHeader>
          <PanelContent>
            <Card
              className={isActive ? classNames(classes.cardActive, freeContract && classes.freeContractActive) : ''}
              data-e2e={'ContractFlowDurationMileage'}
            >
              <CardContent>
                {freeContract ? (
                  <TextField
                    fullWidth={true}
                    disabled={!isActive}
                    value={duration.value > 0 ? duration.value : ''}
                    margin="dense"
                    label={t('Select duration (months)')}
                    data-e2e={'ContractFlowDurationMileage__freeContract-duration'}
                    // tslint:disable-next-line:jsx-no-lambda
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length <= 10) {
                        handleDurationChange(zeroOrNumber(e.target.value))
                      }
                    }}
                    type="number"
                  />
                ) : (
                  <React.Fragment>
                    <InputLabel className={classes.inputLabel}>
                      {!disableDurationSlider && t('Select duration (months)')}
                    </InputLabel>
                    <ContractFlowDurationMileageSlider
                      minLabel={duration ? `${duration.min} ${t('mos.')}` : '0'}
                      maxLabel={duration ? `${duration.max} ${t('mos.')}` : '60'}
                      selectedLabel={
                        <span data-e2e="ContractFlowDurationMileage__freeContract-duration__labelValue">
                          {duration ? `${duration.value} ${t('months')}` : '0'}
                        </span>
                      }
                      convertedLabel={duration ? `${duration.value / 12} ${t('years')}` : `0 ${t('years')}`}
                      data={duration}
                      onChange={handleDurationChange}
                      disabled={!isActive || disableDurationSlider}
                    />
                  </React.Fragment>
                )}

                {freeContract ? (
                  <React.Fragment>
                    <TextField
                      fullWidth={true}
                      disabled={!isActive || (!duration && !mileage.value)}
                      value={mileage.value! > 0 ? mileage.value : ''}
                      margin="dense"
                      InputProps={{
                        classes: {
                          underline: highMileageWarning ? classes.highMileageWarningInput : '',
                        },
                      }}
                      label={t('Select kilometers (in total)')}
                      data-e2e={'ContractFlowDurationMileage__freeContract-mileage'}
                      // tslint:disable-next-line:jsx-no-lambda
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length <= 10) {
                          handleMileageChange(zeroOrNumber(e.target.value))
                        }
                      }}
                      type="number"
                    />
                    {highMileageWarning && (
                      <div className={classes.highMileageWarning}>
                        {t('Please note that you have chosen a very high mileage')}
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <InputLabel className={classes.inputLabel}>
                      {!disableMileageSlider && t('Select kilometers (in total)')}
                    </InputLabel>
                    <ContractFlowDurationMileageSlider
                      minLabel={mileage ? `${formatMileage(mileage.min)} km` : '0 km'}
                      maxLabel={mileage ? `${formatMileage(mileage.max)} km` : `${formatMileage(10000)} km`}
                      selectedLabel={mileage.value ? `${formatMileage(mileage.value)} km` : '0 km'}
                      convertedLabel={
                        duration.value && mileage.value
                          ? this.estimatedMileagePerYear(mileage.value, duration.value)
                          : this.estimatedMileagePerYear(0, 0)
                      }
                      data={mileage}
                      onChange={handleMileageChange}
                      disabled={!isActive || disableMileageSlider}
                    />
                  </React.Fragment>
                )}
              </CardContent>
            </Card>
          </PanelContent>
        </Panel>
      </React.Fragment>
    )
  }

  private isActive = (): boolean => {
    const { freeContract, active, value, activeEnum } = this.props
    const { duration, mileage } = value
    if (freeContract) {
      return active >= activeEnum
    } else {
      return duration.max > 0 || mileage.max > 0
    }
  }

  private handleDurationChange = (value: number) => {
    const { props } = this

    const mileageList = getMileageList(props.durations, value, props.template)
    const mileage = getMileageValues(mileageList)

    const mileageValue =
      props.value.mileage.value > 0
        ? props.value.mileage.value
        : getClosestAllowedValue(props.value.mileage.value, mileageList)

    const values = {
      ...props.value,
      duration: {
        ...props.value.duration,
        value,
        wantedValue: value,
      },
      mileage: {
        ...mileage,
        value: mileageValue,
        wantedValue: props.value.mileage.wantedValue,
      },
    }

    this.handleValueChange(values)
  }

  private handleMileageChange = (value: number) => {
    const { props } = this

    const values = {
      ...props.value,
      mileage: {
        ...props.value.mileage,
        value,
        wantedValue: value,
      },
    }

    this.handleValueChange(values)
  }

  private highMileageWarning = () => {
    const { value } = this.props

    return value.mileage.value > defaultHighMilegaWarningLimit
  }

  private handleValueChange = (values: IDurationMileageValuesVer1) => {
    const isValid = this.checkValid({ duration: values.duration.value, mileage: values.mileage.value })
    this.props.onChange(values, isValid)
  }

  private checkValid = (
    values: { duration?: number; mileage?: number } = {
      duration: this.props.value.duration.value,
      mileage: this.props.value.mileage.value,
    },
  ) => {
    let isValid = true
    const duration = values.duration === undefined ? this.props.value.duration.value : values.duration
    const mileage = values.mileage === undefined ? this.props.value.mileage.value : values.mileage

    if (!duration || !mileage) {
      isValid = false
    }

    return isValid
  }

  private estimatedMileagePerYear = (mileage: number, duration: number) => {
    let convertedMileage = 0

    if (duration > 0) {
      const years = duration / 12
      convertedMileage = Math.round(mileage / years)
    }

    return `${t('Estimated %mileage km/year', { mileage: formatMileage(convertedMileage) })}`
  }
}

export default withStyles(styles)(ContractFlowDurationMileageVer1)
